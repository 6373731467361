import { parseDocUrl } from "@/api/fetchDisclosureList/common/parseDocUrl";
import { parseTopic } from "@/api/fetchDisclosureList/common/parseTopic";
import { GQL_ENUM_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_DISCLOSURE_ITEM } from "@/types/PresenterModel/PV_DISCLOSURE_ITEM";
import { API_RES_HOCHIMINH_LIST_ITEM } from "@/types/apiModel/Disclosure/API_RES_DISCLOSURE_HOCHIMINH";

export function parseHochiminhListItem(
  v: API_RES_HOCHIMINH_LIST_ITEM,
  exchangeType: GQL_ENUM_EXCHANGE_TYPE
): PV_DISCLOSURE_ITEM | null {
  if (!v.id) return null;

  const fileUrl = parseDocUrl(exchangeType, v.details.fileLink?.[0]);

  return {
    id: v.id,
    dataDate: v.filingDate ?? "",
    securityCode: v.details.secCode ? [v.details.secCode] : undefined,
    securityName: v.korName ?? "",
    orgSecurityName: v.details.secCode ?? "",
    categoryCode: "",
    categoryName: "",
    fileUrl:
      fileUrl ||
      "https://www.hsx.vn/Modules/Cms/Web/NewsByCat/dca0933e-a578-4eaf-8b29-beb4575052c5",
    tags: parseTopic(v.analysisDetails.topicKor),
    summaryShort: v.analysisDetails.summarizeTinyKor ?? "",
    summaryLong:
      (v.analysisDetails.summarizeLongKor ?? "").trim() +
      (fileUrl
        ? ""
        : "\n" +
          `(*이 공시에는 원문에 대한 직접 링크가 제공되지 않습니다. 원문 링크에서 ${v.details.secCode}를 검색하여 확인해보세요.)`),
    exchangeType,
    formData: null
  };
}
