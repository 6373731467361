import "./index.css";

import Form10 from "@/components/DisclosureDetail/FormDetail/Form10";
import Form14 from "@/components/DisclosureDetail/FormDetail/Form14";
import { PV_DISCLOSURE_DETAIL } from "@/shared/Disclosure/types/PresenterModel/PV_DISCLOSURE_DETAIL";

export default function PrintContentDetailForm({
  detail
}: {
  detail: PV_DISCLOSURE_DETAIL;
}) {
  return (
    <>
      <div id="contentBoxTitle">
        <b>상세 내용</b>
      </div>
      <div id="contentBox">
        {detail.categoryType === "form10" && <Form10 data={detail.detail} />}
        {detail.categoryType === "form14" && <Form14 data={detail.detail} />}
      </div>
    </>
  );
}
