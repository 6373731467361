import { NATIONAL_OPTION_LIST } from "@/constants/NATIONAL_OPTION_LIST";
import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import {
  SYS_ZUSTAND_GET,
  SYS_ZUSTAND_SET
} from "@/shared/common/types/zustand/common";
import { contains } from "@/shared/common/utils/array/ArrayUtils";
import { isFieldOf } from "@/shared/common/utils/object/objectUtils";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";
import { format } from "date-fns";
import sub from "date-fns/sub";
import { create } from "zustand";

type STORE = FIELD & { actions: INTERFACE };

/** alias **/
type FIELD = {
  exchangeCode: {
    inputKeyword: string;
    keyword: string;
    selected: PV_SELECT_OPTION<GQL_EXCHANGE_TYPE> | null;
  };
  securityCode: {
    inputKeyword: string;
    keyword: string;
    selected: PV_SELECT_OPTION | null;
  };
  categoryType: {
    inputKeyword: string;
    keyword: string;
    selected: PV_SELECT_OPTION<Array<string>> | null;
  };
  includeTinyKor: string;
  startDate: string;
  endDate: string;
};

export type PV_UI_CONDITION_FIELD = FIELD;

const initField = (): FIELD => {
  const today = new Date();
  return {
    exchangeCode: {
      inputKeyword: "",
      keyword: "",
      selected:
        NATIONAL_OPTION_LIST.find((v) => v.value === GQL_EXCHANGE_TYPE.USA) ||
        null
    },
    securityCode: {
      inputKeyword: "",
      keyword: "",
      selected: null
    },
    categoryType: {
      inputKeyword: "",
      keyword: "",
      selected: null
    },
    includeTinyKor: "",
    startDate: format(sub(today, { years: 1 }), "yyyy/MM/dd"),
    endDate: format(today, "yyyy/MM/dd")
  };
};

const SelectTypeFieldList = [
  "exchangeCode" as const,
  "securityCode" as const,
  "categoryType" as const
];

const StringTypeFieldList = [
  "includeTinyKor" as const,
  "startDate" as const,
  "endDate" as const
];

const setInputKeyword = (
  set: SYS_ZUSTAND_SET<STORE>,
  get: SYS_ZUSTAND_GET<STORE>,
  target: string,
  value: string
) => {
  const bs = get();
  if (contains(SelectTypeFieldList, target) && isFieldOf(bs, target)) {
    set({
      [target]: {
        ...bs[target],
        inputKeyword: value,
        keyword: value
      }
    });
  }
};
const setStringInput = (
  set: SYS_ZUSTAND_SET<STORE>,
  get: SYS_ZUSTAND_GET<STORE>,
  target: string,
  value: string
) => {
  const bs = get();
  if (contains(StringTypeFieldList, target) && isFieldOf(bs, target)) {
    set({
      [target]: value
    });
  }
};

const selectItem = (
  set: SYS_ZUSTAND_SET<STORE>,
  get: SYS_ZUSTAND_GET<STORE>,
  target: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: PV_SELECT_OPTION<any> | null
) => {
  const bs = get();
  if (isFieldOf(bs, target)) {
    if (contains(SelectTypeFieldList, target)) {
      if (
        target === "exchangeCode" &&
        bs.exchangeCode.selected?.value !== value?.value
      ) {
        set({
          exchangeCode: {
            inputKeyword: value ? value?.resultLabel || value?.shortLabel : "",
            keyword: value ? value?.resultLabel || value?.shortLabel : "",
            selected: value
          },
          securityCode: {
            inputKeyword: "",
            keyword: "",
            selected: null
          },
          categoryType: {
            inputKeyword: "",
            keyword: "",
            selected: null
          }
        });
      } else {
        set({
          [target]: {
            inputKeyword: value ? value?.resultLabel || value?.shortLabel : "",
            keyword: value ? value?.resultLabel || value?.shortLabel : "",
            selected: value
          }
        });
      }
    }
  }
};

type INTERFACE = {
  setInputKeyword: (target: string, value: string) => void;
  setStringInput: (target: string, value: string) => void;
  selectItem: <T>(target: string, value: PV_SELECT_OPTION<T> | null) => void;
  loadInitialParams: (
    exchangeCode: PV_SELECT_OPTION<GQL_EXCHANGE_TYPE> | null,
    securityCode: PV_SELECT_OPTION | null
  ) => void;
  resetByExchangeCode: () => void;
  reset: () => void;
};

const initInterface = (
  set: SYS_ZUSTAND_SET<STORE>,
  get: SYS_ZUSTAND_GET<STORE>
): INTERFACE => {
  return {
    setInputKeyword: (target: string, value: string) =>
      setInputKeyword(set, get, target, value),
    setStringInput: (target: string, value: string) =>
      setStringInput(set, get, target, value),
    selectItem: <T>(target: string, value: PV_SELECT_OPTION<T> | null) =>
      selectItem(set, get, target, value),
    loadInitialParams: (
      exchangeCode: PV_SELECT_OPTION<GQL_EXCHANGE_TYPE> | null,
      securityCode: PV_SELECT_OPTION | null
    ) =>
      set({
        ...initField(),
        exchangeCode: {
          inputKeyword: exchangeCode?.longLabel ?? "",
          keyword: exchangeCode?.longLabel ?? "",
          selected: exchangeCode
        },
        securityCode: {
          inputKeyword: securityCode?.value ?? "",
          keyword: securityCode?.value ?? "",
          selected: securityCode
        }
      }),
    resetByExchangeCode: () =>
      set(() => ({
        exchangeCode: {
          inputKeyword: "",
          keyword: "",
          selected: null
        },
        securityCode: {
          inputKeyword: "",
          keyword: "",
          selected: null
        },
        categoryType: {
          inputKeyword: "",
          keyword: "",
          selected: null
        }
      })),
    reset: () => set(initField())
  };
};

export const useUiConditionStore = create<STORE>((set, get) => ({
  ...initField(),
  actions: {
    ...initInterface(set, get)
  }
}));
