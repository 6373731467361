import { useSearchConditionStore } from "@/components/_store/zustand/useSearchConditionStore";
import { useSelectItemStore } from "@/components/_store/zustand/useSelectItemStore";
import useRQDisclosureList from "@/hook/RQ/useRQDisclosureList";
import { useEffect, useState } from "react";

export const useDisclosureList = () => {
  const searchCond = useSearchConditionStore((state) => state.options);
  const selectItemActions = useSelectItemStore((state) => state.actions);
  const selectedItem = useSelectItemStore((state) => state.item);
  const [moveTop, setMoveTop] = useState(false);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isInitialLoading,
    isFetchingNextPage
  } = useRQDisclosureList(searchCond);

  useEffect(() => {
    selectItemActions.reset();
    setMoveTop(true);
  }, [searchCond]);

  useEffect(() => {
    if (!isInitialLoading && !selectedItem && data?.pages[0]?.list?.[0]) {
      selectItemActions.selectItem("1_0", data?.pages[0]?.list?.[0]);
    }
  }, [isInitialLoading]);

  return {
    data,
    moveTop,
    setMoveTop,
    hasNextPage,
    fetchNextPage,
    exchangeType: searchCond.exchangeType,
    isLoading: isInitialLoading,
    isFetchingNextPage
  };
};
