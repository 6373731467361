import { useSearchConditionStore } from "@/components/_store/zustand/useSearchConditionStore";
import { useUiConditionStore } from "@/components/_store/zustand/useUiConditionStore";
import { AMP_CLICK_CONDITION_SEARCH_BTN, logEvent } from "@/utils/logEvent";
import { uiCondToParamCond } from "./uiCondToParamCond";

export function SearchButton() {
  const conditionActions = useSearchConditionStore((state) => state.actions);
  const uiCondition = useUiConditionStore((state) => state);

  const handleOnSearch = () => {
    const params = uiCondToParamCond({
      exchangeCode: uiCondition.exchangeCode,
      securityCode: uiCondition.securityCode,
      categoryType: uiCondition.categoryType,
      includeTinyKor: uiCondition.includeTinyKor,
      startDate: uiCondition.startDate,
      endDate: uiCondition.endDate
    });

    logEvent(AMP_CLICK_CONDITION_SEARCH_BTN, params);

    conditionActions.setCondition(params);
  };
  return (
    <button id="search" type="button" onClick={handleOnSearch}>
      조회
    </button>
  );
}
