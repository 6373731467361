import { NATIONAL_OPTION_LIST } from "@/constants/NATIONAL_OPTION_LIST";
import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { SYS_ZUSTAND_SET } from "@/shared/common/types/zustand/common";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";
import { create } from "zustand";

type paramType = {
  nationCode: string | null;
  securityValue: PV_SELECT_OPTION | null;
};

type STORE = FIELD & { actions: INTERFACE };

/** alias **/
type FIELD = {
  initialParams: {
    exchangeCode: PV_SELECT_OPTION<GQL_EXCHANGE_TYPE> | null;
    securityCode: PV_SELECT_OPTION | null;
  } | null;
};

const initField = (): FIELD => {
  return {
    initialParams: null
  };
};

const setParams = (set: SYS_ZUSTAND_SET<STORE>, params: paramType) => {
  const nationCode = params.nationCode;
  const securityValue = params.securityValue;

  set({
    initialParams: {
      exchangeCode: nationCode
        ? NATIONAL_OPTION_LIST.find((v) => v.value === nationCode) ?? null
        : null,
      securityCode: securityValue
    }
  });
};

type INTERFACE = {
  setParams: (params: paramType) => void;
  reset: () => void;
};

const initInterface = (set: SYS_ZUSTAND_SET<STORE>): INTERFACE => {
  return {
    setParams: (params) => setParams(set, params),
    reset: () => set(initField())
  };
};

export const useInitialConditionStore = create<STORE>((set) => ({
  ...initField(),
  actions: {
    ...initInterface(set)
  }
}));
