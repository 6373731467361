export const arrayDiff = (arr1: unknown[], arr2: unknown[]) => {
  const set2 = new Set(arr2);
  return arr1.some((v) => !set2.has(v));
};

export const isSubset = (mainList: string[], targetList: string[]) => {
  if (mainList.length < targetList.length) return false;
  const target = [...new Set(targetList)].sort((a, b) => a.localeCompare(b));
  const main = [...new Set(mainList)].sort((a, b) => a.localeCompare(b));

  const maxLen = main.length;
  const targetMaxLen = target.length;

  let i = 0;
  let j = 0;

  while (i < maxLen) {
    if (main[i] === target[j]) {
      j += 1;
    }
    if (j === targetMaxLen) {
      return true;
    }
    if (main[i][0] > target[j][0]) {
      return false;
    }
    i += 1;
  }
  return false;
};

export function contains<T extends string>(
  list: ReadonlyArray<T>,
  value: string
): value is T {
  return list && list.some((item) => item === value);
}
