import { convertCategoryCodeToName } from "@/api/fetchDisclosureList/common/convertCategoryCodeToName";
import { parseDocUrl } from "@/api/fetchDisclosureList/common/parseDocUrl";
import { parseTopic } from "@/api/fetchDisclosureList/common/parseTopic";
import { GQL_ENUM_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_DISCLOSURE_ITEM } from "@/types/PresenterModel/PV_DISCLOSURE_ITEM";
import { API_RES_SHANGHAI_LIST_ITEM } from "../../../types/apiModel/Disclosure/API_RES_DISCLOSURE_SHANGHAI";

export function parseShanghaiListItem(
  v: API_RES_SHANGHAI_LIST_ITEM,
  exchangeType: GQL_ENUM_EXCHANGE_TYPE
): PV_DISCLOSURE_ITEM | null {
  if (!v.id) return null;

  return {
    id: v.id,
    dataDate: v.dataDate ?? "",
    securityCode: v.details.secCode ? [v.details.secCode] : [],
    securityName: v.korName ?? "",
    orgSecurityName: v.details.secName ?? "",
    categoryCode: v.details.categoryId ?? "",
    categoryName: convertCategoryCodeToName(exchangeType, v.details.categoryId),
    tags: parseTopic(v.analysisDetails.topicKor),
    summaryShort: v.analysisDetails.summarizeTinyKor ?? "",
    summaryLong: v.analysisDetails.summarizeLongKor ?? "",
    fileUrl: parseDocUrl(exchangeType, v.details.fileLink),
    exchangeType,

    formData: null
  };
}
