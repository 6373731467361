import { PV_FILINGS_SEARCH_COND_ITEM } from "@/components/_store/zustand/useSearchConditionStore";
import { PV_UI_CONDITION_FIELD } from "@/components/_store/zustand/useUiConditionStore";

export function uiCondToParamCond(
  params: PV_UI_CONDITION_FIELD
): Partial<PV_FILINGS_SEARCH_COND_ITEM> {
  const exchangeType = params.exchangeCode.selected?.value
    ? params.exchangeCode.selected?.value
    : undefined;

  const securityCode = (
    params.securityCode.selected?.value || ""
  ).toUpperCase();

  const startDate = params.startDate;
  const endDate = params.endDate;
  const includeTinyKor = params.includeTinyKor.trim()
    ? params.includeTinyKor
    : undefined;
  const categoryId = params.categoryType.selected?.value
    ? `[${params.categoryType.selected.value.map((v) => `"${v}"`).join(",")}]`
    : undefined;

  return {
    exchangeType,
    securityCode,
    startDate,
    endDate,
    includeTinyKor,
    categoryId
  };
}
