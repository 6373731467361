import CryptoJS from "crypto-js";

const encryptWithUnixTime = () => {
  const unixTime = Date.now();
  const salt = import.meta.env.VITE_1LCOWKYBH6;
  const key = CryptoJS.enc.Utf8.parse(import.meta.env.VITE_2G35T7HMTF);
  const iv = CryptoJS.enc.Utf8.parse(import.meta.env.VITE_3XHHAVGAZW);

  const cipher = CryptoJS.AES.encrypt(`${salt}${unixTime}`, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const encrypted = cipher.toString();

  return { encrypted, unixTime };
};

export default encryptWithUnixTime;
