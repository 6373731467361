import React, { useCallback, useRef } from "react";
import "./index.css";

import { useSelectItemStore } from "@/components/_store/zustand/useSelectItemStore";
import { PV_DISCLOSURE_ITEM } from "@/types/PresenterModel/PV_DISCLOSURE_ITEM";
import {
  AMP_CLICK_LIST_ITEM,
  AMP_CLICK_LIST_ORIGIN_PAGE_BTN,
  logEvent
} from "@/utils/logEvent";
import { format } from "date-fns";

type Props = {
  data: PV_DISCLOSURE_ITEM;
  uniqueKey: string;
};

function CellDate({ dataDate }: { dataDate: string }) {
  return (
    <div className="cell">
      <span>{format(new Date(dataDate), "yyyy/MM/dd")}</span>
    </div>
  );
}

function CellTime({ dataDate }: { dataDate: string }) {
  return (
    <div className="cell">
      <span>{format(new Date(dataDate), "HH:mm:ss")}</span>
    </div>
  );
}
function CellCategoryName({ categoryName }: { categoryName: string }) {
  return (
    <div className="cell">
      <span>{categoryName}</span>
    </div>
  );
}
function Cell({ text }: { text: string }) {
  return (
    <div className="cell ">
      <span className="ellipsis" title={text}>
        {text}
      </span>
    </div>
  );
}

function CellSecurityName({
  securityName,
  orgSecurityName
}: {
  securityName: string;
  orgSecurityName: string;
}) {
  return <Cell text={securityName || orgSecurityName} />;
}
function CellSecurityCode({ securityCode }: { securityCode?: Array<string> }) {
  return (
    <div className="cell ">
      <span
        className="ellipsis"
        title={securityCode?.[0] ? securityCode.join(", ") : ""}
      >
        {securityCode?.[0]
          ? `${securityCode[0]}${
              securityCode.length > 1 ? ` (+${securityCode.length - 1})` : ""
            } `
          : ""}
      </span>
    </div>
  );
}

function CellDocLink({
  filingId,
  originURL
}: {
  filingId: string;
  originURL: string;
}) {
  const handleOriginPageClick = useCallback(() => {
    logEvent(AMP_CLICK_LIST_ORIGIN_PAGE_BTN, {
      originURL,
      filingId
    });
  }, []);

  return (
    <div className="cell">
      {originURL ? (
        <a
          className="org-doc-button"
          href={originURL}
          rel="noopener noreferrer"
          download
          target="_blank"
          onClick={handleOriginPageClick}
        >
          조회
        </a>
      ) : (
        <p className="org-doc-button">조회</p>
      )}
    </div>
  );
}

function Row({ data, uniqueKey }: Props) {
  const isSelected = useSelectItemStore(
    (state) => state.item?.uniqueKey === uniqueKey
  );

  const ref = useRef<HTMLDivElement>(null);

  const actions = useSelectItemStore((state) => state.actions);

  const handleOnClick = useCallback(() => {
    if (!isSelected) {
      logEvent(AMP_CLICK_LIST_ITEM, {
        id: data.id,
        scrollY: ref.current?.scrollTop
      });
      actions.selectItem(uniqueKey, data);
    }
  }, [isSelected, ref]);

  return (
    <div
      className={["row", isSelected ? "selected" : ""].join(" ")}
      onClick={handleOnClick}
      ref={ref}
    >
      <CellSecurityCode securityCode={data.securityCode} />
      <CellSecurityName
        securityName={data.securityName}
        orgSecurityName={data.orgSecurityName}
      />
      <CellDate dataDate={data.dataDate} />
      <CellTime dataDate={data.dataDate} />
      <CellCategoryName categoryName={data.categoryName} />
      <Cell text={data.summaryShort} />
      <CellDocLink filingId={data.id} originURL={data.fileUrl} />
    </div>
  );
}

export default React.memo(Row);
