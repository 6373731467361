import {
  FORM_10_CODES,
  FORM_10_FIELD_SETTING,
  FORM_14_CODES,
  FORM_DETAIL_SUPPORT_CODES
} from "@/shared/Disclosure/constants/DisclosureViewCodeConstants";
import { PV_DISCLOSURE_10_FORM_DETAIL } from "@/shared/Disclosure/types/PresenterModel/PV_DISCLOSURE_10_FORM_DETAIL";
import {
  PV_DISCLOSURE_DETAIL,
  PV_DISCLOSURE_DETAIL_FORM10,
  PV_DISCLOSURE_DETAIL_FORM14
} from "@/shared/Disclosure/types/PresenterModel/PV_DISCLOSURE_DETAIL";
import {
  VM_DISCLOSURE_10_FORM,
  VM_DISCLOSURE_DEF14_FORM
} from "@/shared/Disclosure/types/ViewModel/VM_DISCLOSURE";
import { SYS_ZUSTAND_SET } from "@/shared/common/types/zustand/common";
import { contains } from "@/shared/common/utils/array/ArrayUtils";
import { isEmptyObject } from "@/shared/common/utils/object/objectUtils";
import { PV_DISCLOSURE_ITEM } from "@/types/PresenterModel/PV_DISCLOSURE_ITEM";
import { create } from "zustand";

const semiPostfixRegx = /(;)$/;

type STORE = FIELD & { actions: INTERFACE };

type ITEM = {
  uniqueKey: string;
  selectedItem: PV_DISCLOSURE_ITEM;
  detail: PV_DISCLOSURE_DETAIL | null;
};

type FIELD = {
  item: ITEM | null;
};

const initField = (): FIELD => {
  return {
    item: null
  };
};

const extractorFormType10 = (rawData: VM_DISCLOSURE_10_FORM) => {
  const res: PV_DISCLOSURE_10_FORM_DETAIL = {};

  FORM_10_FIELD_SETTING.forEach((v) => {
    if (rawData[v.fieldKey]) {
      switch (v.type) {
        case "string":
          res[v.key] = rawData[v.fieldKey];
          break;
        case "json":
          if (!isEmptyObject(rawData[v.fieldKey])) {
            res[v.key] = Object.keys(rawData[v.fieldKey] ?? {}).map(
              (v2, i) => ({
                no: i + 1,
                title: v2,
                text: rawData[v.fieldKey]?.[v2] ?? ""
              })
            );
          }
          break;
        default:
          break;
      }
    }
  });

  if (
    rawData.financeEarningKor ||
    rawData.financeLiquidityKor ||
    rawData.financeEquityKor ||
    rawData.financeOthersKor
  ) {
    res.finance = [];
    if (rawData.financeEarningKor) {
      res.finance.push({
        no: res.finance.length + 1,
        title: "매출, 영업이익",
        text: rawData.financeEarningKor
      });
    }
    if (rawData.financeLiquidityKor) {
      res.finance.push({
        no: res.finance.length + 1,
        title: "유동성, 현금",
        text: rawData.financeLiquidityKor
      });
    }
    if (rawData.financeEquityKor) {
      res.finance.push({
        no: res.finance.length + 1,
        title: "주주 이익",
        text: rawData.financeEquityKor
      });
    }
    if (rawData.financeOthersKor) {
      res.finance.push({
        no: res.finance.length + 1,
        title: "기타",
        text: rawData.financeOthersKor
      });
    }
  }

  return res;
};

const extractorFormType14 = (rawData: VM_DISCLOSURE_DEF14_FORM) =>
  rawData?.proposalKorList
    ?.filter((v) => v.trim())
    ?.map((v, i) => ({
      no: i + 1,
      text: (v || "").replace(semiPostfixRegx, ".")
    })) ?? [];

const selectItem = (
  set: SYS_ZUSTAND_SET<STORE>,
  uniqueKey: string,
  selectedItem: PV_DISCLOSURE_ITEM
) => {
  const res: { item: ITEM } = {
    item: {
      uniqueKey,
      selectedItem,
      detail: null
    }
  };

  if (
    selectedItem.categoryCode &&
    contains(FORM_DETAIL_SUPPORT_CODES, selectedItem.categoryCode) &&
    (selectedItem.formData?.form10 || selectedItem.formData?.form14)
  ) {
    if (
      selectedItem.formData?.form10 &&
      contains(FORM_10_CODES, selectedItem.categoryCode)
    ) {
      const detail = extractorFormType10(selectedItem.formData?.form10);

      if (Object.keys(detail)) {
        const val: PV_DISCLOSURE_DETAIL_FORM10 = {
          id: selectedItem.id,
          categoryType: "form10",
          detail
        };
        res.item.detail = val;
      }
    } else if (
      selectedItem.formData?.form14 &&
      contains(FORM_14_CODES, selectedItem.categoryCode)
    ) {
      const detail = extractorFormType14(selectedItem.formData?.form14);

      if (detail.length > 0) {
        const val: PV_DISCLOSURE_DETAIL_FORM14 = {
          id: selectedItem.id,
          categoryType: "form14",
          detail
        };

        res.item.detail = val;
      }
    }
  }

  set(res);
};

type INTERFACE = {
  selectItem: (uniqueKey: string, selectedItem: PV_DISCLOSURE_ITEM) => void;
  reset: () => void;
};

const initInterface = (set: SYS_ZUSTAND_SET<STORE>): INTERFACE => {
  return {
    selectItem: (uniqueKey: string, selectedItem) =>
      selectItem(set, uniqueKey, selectedItem),
    reset: () => set(initField())
  };
};

export const useSelectItemStore = create<STORE>((set) => ({
  ...initField(),
  actions: {
    ...initInterface(set)
  }
}));
