import "../common/ControlBtn/index.css";
import "./index.css";

import { ReactComponent as PlusBtnSvg } from "./plusBtn.svg";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export default function PlusBtn(props: Props) {
  return (
    <button
      type="button"
      {...props}
      className={[
        "bordered atomic atoms simple-part plus-btn bg-white control-btn",
        props.className
      ].join(" ")}
    >
      <PlusBtnSvg />
    </button>
  );
}
