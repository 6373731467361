import { CATEGORY_OPTIONS } from "@/shared/Disclosure/constants/DisclosureCategoryNameConstants";
import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";

export default function useCategoryCodeList(
  nationCode?: GQL_EXCHANGE_TYPE | null
): {
  list: PV_SELECT_OPTION<string[]>[];
} {
  return { list: nationCode ? CATEGORY_OPTIONS[nationCode] : [] };
}
