import { PrintContentDetail } from "@/components/PrintArea/ContentArea/PrintContentDetail/PrintContentDetail";
import PrintContentDetailForm from "@/components/PrintArea/ContentArea/PrintContentDetailForm";
import { PrintContentMainTitle } from "@/components/PrintArea/ContentArea/PrintContentHeader/PrintContentHeader";
import PrintSecurityInfo from "@/components/PrintArea/ContentArea/PrintSecurity";
import PrintTail from "@/components/PrintArea/ContentArea/PrintTail";
import PrintContentTitle from "@/components/PrintArea/ContentArea/PrintTitle";
import { usePrintStore } from "@/components/_store/zustand/usePrintStore";
import { useSelectItemStore } from "@/components/_store/zustand/useSelectItemStore";
import PrintCustomerLogo from "@/components/atomic/atoms/SimplePart/Logo/PrintCustomerLogo";
import _ from "lodash";
import { useCallback, useEffect } from "react";
import "./index.css";

const printOpenConsumer = _.debounce((fn: () => void) => {
  fn();
}, 1000);
const printCloseConsumer = _.debounce((fn: () => void) => {
  fn();
}, 300);

/**
 * wrap content area to avoid rendering when don't need to print for performance
 * */
export default function PrintArea() {
  const needPrint = usePrintStore((state) => state.state.needPrint);
  return (
    <>
      {needPrint && (
        <div id="printArea">
          <PrintTable />
        </div>
      )}
    </>
  );
}
function PrintTable() {
  const printActions = usePrintStore((state) => state.actions);

  const handleDoPrint = useCallback(() => {
    printOpenConsumer(() => {
      window.print();
      printCloseConsumer(() => {
        printActions.setNeedPrint(false);
      });
    });
  }, []);

  useEffect(() => {
    handleDoPrint();
  }, []);
  return (
    <>
      <table id="printTable">
        <thead>
          <tr>
            <td>
              <div className="print-layout-header-space">&nbsp;</div>
            </td>
          </tr>
        </thead>
        <Tbody />
      </table>
      <div className="print-layout-header">
        <PrintCustomerLogo />
      </div>
    </>
  );
}

function Tbody() {
  const selectedItem = useSelectItemStore((state) => state.item);

  return (
    <tbody id="printContentArea">
      <tr>
        <td>
          <table
            style={{
              width: "calc(100% - 2cm)",
              margin: "0 1cm"
            }}
          >
            <tbody>
              <tr>
                <td>
                  <PrintContentMainTitle />
                </td>
              </tr>
              <tr>
                <td style={{ height: "1.6cm" }}></td>
              </tr>
              {selectedItem && (
                <>
                  <tr>
                    <td>
                      <PrintSecurityInfo
                        selectedItem={selectedItem.selectedItem}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "0.8cm" }}></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "2px solid #595959",
                        padding: "0.4cm 0.3cm"
                      }}
                    >
                      <PrintContentTitle
                        selectedItem={selectedItem.selectedItem}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        height: "0.7cm",
                        borderBottom: "1px solid #595959",
                        padding: "0.4cm 0.3cm"
                      }}
                    >
                      <PrintContentDetail
                        summaryLong={selectedItem.selectedItem.summaryLong}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "1cm" }}></td>
                  </tr>
                  {selectedItem.detail && selectedItem.detail.categoryType && (
                    <>
                      <tr>
                        <td style={{ padding: "0.4cm 0.3cm" }}>
                          <PrintContentDetailForm
                            detail={selectedItem.detail}
                          />
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <span>- 끝 -</span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "1cm" }}></td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0.4cm 0.3cm" }}>
                      <PrintTail />
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  );
}
