import { fetchSecurityCatalog } from "@/api/fetchSecurityCatalog";
import { useInitialConditionStore } from "@/components/_store/zustand/useInitialConditionStore";
import { SECURITY_HISTORY_SIZE } from "@/constants/SECURITY_HISTORY_SIZE";
import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";
import { KIS_COUNTRY_CODE } from "@/types/enum/COUNTRY_CODE";
import { KIS_EXCHANGE_CODE } from "@/types/enum/EXCHANGE";
import { getJsonData, setJsonData } from "@/utils/history";
import { makeSecurityCodeKey } from "@/utils/makeSecurityCodeKey";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function nationCodeFromRawParam(
  orgCountryCode: string | null,
  orgExchangeCode?: string | null
) {
  if (!orgCountryCode) {
    return null;
  }

  const countryCode = (orgCountryCode || "").toUpperCase();
  const exchangeCode = (orgExchangeCode || "").toUpperCase();

  if (countryCode === KIS_COUNTRY_CODE.US) {
    return GQL_EXCHANGE_TYPE.USA;
  }

  if (countryCode === KIS_COUNTRY_CODE.HK) {
    return GQL_EXCHANGE_TYPE.HONGKONG;
  }

  if (countryCode === KIS_COUNTRY_CODE.CN) {
    if (exchangeCode === KIS_EXCHANGE_CODE.SHS) {
      return GQL_EXCHANGE_TYPE.SHANGHAI;
    }

    if (exchangeCode === KIS_EXCHANGE_CODE.SZS) {
      return GQL_EXCHANGE_TYPE.SHENZHEN;
    }
  }

  if (countryCode === KIS_COUNTRY_CODE.JP) {
    return GQL_EXCHANGE_TYPE.JAPAN;
  }

  if (countryCode === KIS_COUNTRY_CODE.VN) {
    if (exchangeCode === KIS_EXCHANGE_CODE.HNX) {
      return GQL_EXCHANGE_TYPE.HANOI;
    }
    if (exchangeCode === KIS_EXCHANGE_CODE.HSX) {
      return GQL_EXCHANGE_TYPE.HOCHIMINH;
    }
  }

  return null;
}
export function InitialLoader() {
  const initActions = useInitialConditionStore((state) => state.actions);

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    const countryCode = urlParams.get("country_code");
    const exchangeCode =
      urlParams.get("exchange") || urlParams.get("exchange_code");

    const exchangeType = nationCodeFromRawParam(countryCode, exchangeCode);

    if (exchangeType) {
      const securityCode =
        urlParams.get("sec_code") || urlParams.get("security_code");
      if (securityCode && exchangeType) {
        fetchSecurityCatalog({
          exchangeType,
          securityCode,
          searchType: true
        }).then((v) => {
          const securityOption: PV_SELECT_OPTION | null =
            v && v[0]
              ? {
                  shortLabel: v[0].securityCode,
                  longLabel: v[0].securityKorName,
                  value: v[0].securityCode
                }
              : null;

          if (securityOption) {
            const befHistories: PV_SELECT_OPTION<string>[] =
              getJsonData(makeSecurityCodeKey(exchangeType)) || [];
            const newList = [
              securityOption,
              ...befHistories.filter(
                (bef) => bef.value !== securityOption.value
              )
            ].slice(0, SECURITY_HISTORY_SIZE);
            setJsonData(makeSecurityCodeKey(exchangeType), newList);
          }

          initActions.setParams({
            nationCode: exchangeType,
            securityValue: securityOption
          });
        });
      } else {
        initActions.setParams({
          nationCode: exchangeType,
          securityValue: null
        });
      }
    }
  }, []);

  return <></>;
}
