import { logEvent as amplitudeLogEvent } from "@amplitude/analytics-browser";

const PREFIX_EVENT = "hts ";

export const AMP_INIT_LIST_PAGE = PREFIX_EVENT + "enter list page";
export const AMP_CLICK_CONDITION_RESET_BTN = PREFIX_EVENT + "click reset btn";
export const AMP_CLICK_CONDITION_SEARCH_BTN = PREFIX_EVENT + "click search btn";
export const AMP_PRESS_ENTER_SECURITY_CODE_CONDITION =
  PREFIX_EVENT + "press enter security code condition";
export const AMP_PRESS_ENTER_SUMMARY_TINY_CONDITION =
  PREFIX_EVENT + "press enter summary tiny condition";
export const AMP_REQUEST_LIST_SEARCH = PREFIX_EVENT + "request list search";
export const AMP_CLICK_LIST_ITEM = PREFIX_EVENT + "click list item";
export const AMP_CLICK_LIST_ORIGIN_PAGE_BTN =
  PREFIX_EVENT + "click list origin page btn";
export const AMP_CLICK_DETAIL_FONT_SIZE_UP_BTN =
  PREFIX_EVENT + "click detail font size up btn";
export const AMP_CLICK_DETAIL_FONT_SIZE_DOWN_BTN =
  PREFIX_EVENT + "click detail font size down btn";
export const AMP_CLICK_DETAIL_FONT_SIZE_RESET_BTN =
  PREFIX_EVENT + "click detail font size reset btn";
export const AMP_CLICK_DETAIL_ORIGIN_PAGE_BTN =
  PREFIX_EVENT + "click detail origin page btn";
export const AMP_CLICK_DETAIL_PRINT_BTN =
  PREFIX_EVENT + "click detail print btn";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logEvent(eventName: string, properties?: Record<string, any>) {
  amplitudeLogEvent(eventName, properties);
}
