import React, {
  KeyboardEventHandler,
  MouseEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useRef
} from "react";
import "./index.css";

import { useSelectListIndexStore } from "@/components/atomic/atoms/SelectList/SelectListIndexStore";
import CloseBtn from "@/components/atomic/atoms/SimplePart/CloseBtn";
import { DEF_CLEAR_SELECT_ITEM } from "@/constants/SELECT_ITEM";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";

const UL_MAX_HEIGHT = 200;
const UL_PADDING = 2;
// const UL_BORDER = 1;

const preventULScrollEvent: KeyboardEventHandler<HTMLUListElement> = (e) => {
  e.preventDefault();
};

function itemDown(container: HTMLUListElement, idx: number) {
  const target = container?.children[idx] as HTMLLIElement;
  if (!target) {
    return;
  }
  if (container && target.offsetTop > container.scrollTop + UL_MAX_HEIGHT) {
    container.scrollTop =
      target.offsetTop + target.offsetHeight - UL_MAX_HEIGHT - UL_PADDING;
  }
}

function itemUp(container: HTMLUListElement, idx: number) {
  const target = container?.children[idx] as HTMLLIElement;
  if (!target) {
    return;
  }
  if (container && target.offsetTop - UL_PADDING <= container.scrollTop) {
    container.scrollTop = target.offsetTop - UL_PADDING;
  }
}

const genericMemo: <T>(component: T) => T = React.memo;

type SelectItemType<T> = {
  idx: number;
  item: PV_SELECT_OPTION<T>;
  onSelect: (v: PV_SELECT_OPTION<T> | null) => void;
  dataType: string;
  handleOnKeydown: KeyboardEventHandler<HTMLLIElement>;
  handleOnMouseEnter: MouseEventHandler<HTMLLIElement>;
  needDelete?: boolean;
  onDelete?: (v: PV_SELECT_OPTION<T> | null) => void;
};

const SelectItem = genericMemo(function SelectItem<T>({
  idx,
  item,
  onSelect,
  dataType,
  handleOnKeydown,
  needDelete,
  onDelete,
  handleOnMouseEnter
}: SelectItemType<T>) {
  const selected = useSelectListIndexStore((state) => state.idx === idx);

  const handleOnDelete: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onDelete && onDelete(item);
    },
    []
  );

  return (
    <li
      tabIndex={1 + idx}
      className={["item", selected ? "selected" : ""].join(" ")}
      onClick={() => onSelect(item)}
      onKeyDown={handleOnKeydown}
      onMouseEnter={handleOnMouseEnter}
    >
      <div className="short" title={item?.shortLabel}>
        <span>{item?.shortLabel}</span>
      </div>
      {dataType !== "nation" && (
        <div className="long" title={item?.longLabel}>
          <span>{item?.longLabel}</span>
        </div>
      )}
      {needDelete && (
        <div>
          <CloseBtn onClick={handleOnDelete} />
        </div>
      )}
    </li>
  );
});

function IdxHook({
  containerRef
}: {
  containerRef: RefObject<HTMLUListElement>;
}) {
  const store = useSelectListIndexStore((state) => state);
  useEffect(() => {
    if (containerRef.current) {
      if (store.direction === "down") {
        itemDown(containerRef.current, store.idx);
      }
      if (store.direction === "up") {
        itemUp(containerRef.current, store.idx);
      }
    }
  }, [store.idx]);

  return <></>;
}

function ClearItem<T>({
  clearText,
  onSelect,
  handleOnKeydown
}: {
  clearText: string;
  handleOnKeydown: KeyboardEventHandler<HTMLLIElement>;
  onSelect: (v: PV_SELECT_OPTION<T> | null) => void;
}) {
  const store = useSelectListIndexStore((state) => state);
  return (
    <li
      className={["item", store.idx === 0 ? "selected" : ""].join(" ")}
      onClick={() => onSelect(null)}
      tabIndex={0}
      onKeyDown={handleOnKeydown}
      onMouseEnter={() => store.actions.setIdxWhenNotChanging(0)}
    >
      <div>
        <span>{clearText}</span>
      </div>
    </li>
  );
}

export default function SelectList<T = string>({
  list,
  onSelect,
  needClear,
  dataType,
  clearText,
  setRef,
  needDelete,
  onDelete,
  handleOnKeydown
}: {
  list: Array<PV_SELECT_OPTION<T>>;
  onSelect: (v: PV_SELECT_OPTION<T> | null) => void;
  needClear?: boolean;
  needDelete?: boolean;
  dataType: string;
  clearText: string;
  setRef?: (v: React.RefObject<HTMLUListElement>) => void;
  onDelete?: (v: PV_SELECT_OPTION<T> | null) => void;
  handleOnKeydown: KeyboardEventHandler<
    HTMLLIElement | HTMLUListElement | HTMLDivElement
  >;
}) {
  const actions = useSelectListIndexStore((state) => state.actions);

  useEffect(() => {
    actions.init(list.length + (needClear ? 1 : 0));
    return () => actions.reset();
  }, [list]);

  const containerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setRef && setRef(containerRef);
  }, [containerRef.current]);

  return (
    <>
      <IdxHook containerRef={containerRef} />
      <ul
        id={dataType}
        className={["atomic atoms select-list bordered"].join(" ")}
        ref={containerRef}
        onKeyDown={preventULScrollEvent}
      >
        {needClear && (
          <ClearItem
            clearText={clearText}
            handleOnKeydown={handleOnKeydown}
            onSelect={() => {
              onSelect(DEF_CLEAR_SELECT_ITEM);
            }}
          />
        )}
        {list.map((v, idx) => (
          <SelectItem<T>
            idx={idx + (needClear ? 1 : 0)}
            key={String(Object.values(v))}
            item={v}
            onSelect={onSelect}
            dataType={dataType}
            handleOnKeydown={handleOnKeydown}
            needDelete={needDelete}
            onDelete={onDelete}
            handleOnMouseEnter={() =>
              actions.setIdxWhenNotChanging(idx + (needClear ? 1 : 0))
            }
          />
        ))}
      </ul>
    </>
  );
}
