import { contains } from "@/shared/common/utils/array/ArrayUtils";

const PrefixTargetForm = ["3", "3/A", "4", "4/A", "5", "5/A"];

export function padCategoryCode(code?: string) {
  const categoryCode = code ?? "";
  if (contains(PrefixTargetForm, categoryCode)) {
    return `Form ${categoryCode}`;
  }
  return categoryCode;
}
export function formTags(tags?: Array<string>) {
  if (!tags) return "";
  return tags.map((v) => `#${v}`).join(" ");
}
