export enum SELECT_OPTION_TYPE {
  CLEAR = "__CLEAR__",
  NOT_EXISTS = "__NOT_EXISTS__"
}

export type PV_SELECT_OPTION<T = string> = {
  shortLabel: string;
  longLabel: string;
  resultLabel?: string;
  type?: SELECT_OPTION_TYPE.CLEAR | SELECT_OPTION_TYPE.NOT_EXISTS;
  value: T | null;
};
