import { uiCondToParamCond } from "@/components/SearchCondition/Controller/uiCondToParamCond";
import { useInitialConditionStore } from "@/components/_store/zustand/useInitialConditionStore";
import { useSearchConditionStore } from "@/components/_store/zustand/useSearchConditionStore";
import { useUiConditionStore } from "@/components/_store/zustand/useUiConditionStore";
import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { AMP_CLICK_CONDITION_RESET_BTN, logEvent } from "@/utils/logEvent";
import { useCallback, useEffect } from "react";

export function ResetButton() {
  const conditionActions = useSearchConditionStore((state) => state.actions);
  const uiActions = useUiConditionStore((state) => state.actions);
  const uiCondition = useUiConditionStore((state) => state);

  const initialParams = useInitialConditionStore(
    (state) => state.initialParams
  );

  const handleOnReset = useCallback(() => {
    uiActions.reset();
    logEvent(AMP_CLICK_CONDITION_RESET_BTN);
  }, []);

  const conditionReset = useCallback(() => {
    if (initialParams) {
      uiActions.loadInitialParams(
        initialParams.exchangeCode,
        initialParams.securityCode
      );
    }
  }, [initialParams]);

  useEffect(() => {
    conditionReset();
    if (initialParams) {
      const exchangeType: GQL_EXCHANGE_TYPE | null = initialParams.exchangeCode
        ?.value
        ? initialParams.exchangeCode?.value
        : null;
      const securityCode = initialParams.securityCode?.value ?? null;

      const params = {
        exchangeType,
        securityCode
      };

      conditionActions.setCondition(params);
    } else {
      conditionActions.setCondition(
        uiCondToParamCond({
          exchangeCode: uiCondition.exchangeCode,
          securityCode: uiCondition.securityCode,
          categoryType: uiCondition.categoryType,
          includeTinyKor: uiCondition.includeTinyKor,
          startDate: uiCondition.startDate,
          endDate: uiCondition.endDate
        })
      );
    }
  }, [initialParams]);
  return (
    <button id="reset" type="button" onClick={handleOnReset}>
      초기화
    </button>
  );
}
