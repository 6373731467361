import DisclosureDetail from "@/components/DisclosureDetail";
import DisclosureList from "@/components/DisclosureList";
import PrintArea from "@/components/PrintArea";
import SearchCondition from "@/components/SearchCondition";
import { useContentFontStore } from "@/components/_store/zustand/useContentFontStore";
import { usePrintStore } from "@/components/_store/zustand/usePrintStore";
import { useSearchConditionStore } from "@/components/_store/zustand/useSearchConditionStore";
import { useSelectItemStore } from "@/components/_store/zustand/useSelectItemStore";
import { useUiConditionStore } from "@/components/_store/zustand/useUiConditionStore";
import { CAUTION_TEXT } from "@/constants/CAUTION_TEXT";
import { useEffect } from "react";
import "./MainTemplate.css";

export default function MainTemplate() {
  const contentFontActions = useContentFontStore((state) => state.actions);
  const printActions = usePrintStore((state) => state.actions);
  const searchConditionActions = useSearchConditionStore(
    (state) => state.actions
  );
  const selectItemActions = useSelectItemStore((state) => state.actions);
  const uiConditionActions = useUiConditionStore((state) => state.actions);

  const needPrint = usePrintStore((state) => state.state.needPrint);

  const containerStyle: React.CSSProperties = needPrint
    ? { visibility: "hidden", position: "absolute" }
    : { visibility: "visible" };

  useEffect(() => {
    // clear store data
    return () => {
      contentFontActions.reset();
      printActions.reset();
      searchConditionActions.reset();
      selectItemActions.reset();
      uiConditionActions.reset();
    };
  }, []);

  return (
    <>
      <PrintArea />
      <div id="mainLayer" style={containerStyle}>
        <div id="container" className={needPrint ? "print" : ""}>
          <div id="condition">
            <SearchCondition />
          </div>
          <div id="list">
            <DisclosureList />
          </div>
          <div id="detailWrap">
            <div id="detail">
              <DisclosureDetail />
            </div>
            <div id="caution" className="bordered">
              <b>[유의사항] {CAUTION_TEXT}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
