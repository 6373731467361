import { PV_DISCLOSURE_10_FORM_DETAIL } from "@/shared/Disclosure/types/PresenterModel/PV_DISCLOSURE_10_FORM_DETAIL";
import { VM_DISCLOSURE_10_FORM } from "@/shared/Disclosure/types/ViewModel/VM_DISCLOSURE";
import { contains } from "@/shared/common/utils/array/ArrayUtils";

export const USA_AMENDMENT = [
  "3/A",
  "4/A",
  "5/A",
  "6-K/A",
  "8-K/A",
  "10-K/A",
  "10-Q/A",
  "20-F/A",
  "40-F/A",
  "424B3/A",
  "SC 13D/A",
  "SC 13G/A"
];

export type PV_DISCLOSURE_FORM_CODE = {
  REMOVE_INSIDE_EVENT: "REMOVE_INSIDE_EVENT";
  FC_6K: "6-K";
  FC_6KA: "6-K/A";
  FC_8K: "8-K";
  FC_8KA: "8-K/A";
  FC_8K12B: "8-K12B";
  FC_10K: "10-K";
  FC_10KA: "10-K/A";
  FC_10Q: "10-Q";
  FC_DEF14A: "DEF 14A";
  FC_DEFA14A: "DEFA14A";
  FC_PRE14A: "PRE14A";
  FC_3: "3";
  FC_4: "4";
  FC_5: "5";
};

export const REMOVE_INSIDE_EVENT_LABEL = "내부자 지분 변동 제외 (Form 3,4,5)";

export const FORM_CODE_ENUM: PV_DISCLOSURE_FORM_CODE = {
  REMOVE_INSIDE_EVENT: "REMOVE_INSIDE_EVENT",
  FC_6K: "6-K",
  FC_6KA: "6-K/A",
  FC_8K: "8-K",
  FC_8KA: "8-K/A",
  FC_8K12B: "8-K12B",
  FC_10K: "10-K",
  FC_10KA: "10-K/A",
  FC_10Q: "10-Q",
  FC_DEF14A: "DEF 14A",
  FC_DEFA14A: "DEFA14A",
  FC_PRE14A: "PRE14A",
  FC_3: "3",
  FC_4: "4",
  FC_5: "5"
};

type ALL_FORM_ENUM = Omit<PV_DISCLOSURE_FORM_CODE, "REMOVE_INSIDE_EVENT">;
type ALL_FORM_CODES_KEY = ALL_FORM_ENUM[keyof ALL_FORM_ENUM];

export const ALL_FORM_LIST: Array<ALL_FORM_CODES_KEY> = [
  FORM_CODE_ENUM.FC_6K,
  FORM_CODE_ENUM.FC_6KA,
  FORM_CODE_ENUM.FC_8K,
  FORM_CODE_ENUM.FC_8KA,
  FORM_CODE_ENUM.FC_8K12B,
  FORM_CODE_ENUM.FC_10K,
  FORM_CODE_ENUM.FC_10KA,
  FORM_CODE_ENUM.FC_10Q,
  FORM_CODE_ENUM.FC_DEF14A,
  FORM_CODE_ENUM.FC_DEFA14A,
  FORM_CODE_ENUM.FC_PRE14A,
  FORM_CODE_ENUM.FC_3,
  FORM_CODE_ENUM.FC_4,
  FORM_CODE_ENUM.FC_5
];

type INSIDE_EVENT_FORM_ENUM = Pick<
  PV_DISCLOSURE_FORM_CODE,
  "FC_5" | "FC_4" | "FC_3"
>;
type INSIDE_EVENT_FORM_CODES_KEY =
  INSIDE_EVENT_FORM_ENUM[keyof INSIDE_EVENT_FORM_ENUM];

export const INSIDE_EVENT_FORM_LIST: Array<INSIDE_EVENT_FORM_CODES_KEY> = [
  FORM_CODE_ENUM.FC_5,
  FORM_CODE_ENUM.FC_4,
  FORM_CODE_ENUM.FC_3
];
export const NOT_INSIDE_EVENT_FORM_LIST = ALL_FORM_LIST.filter(
  (v) => !contains(INSIDE_EVENT_FORM_LIST, v)
);

const formPrefix = "Form ";

export const FORM_FILTER_LIST = [
  {
    label: FORM_CODE_ENUM.FC_6K,
    value: [FORM_CODE_ENUM.FC_6K, FORM_CODE_ENUM.FC_6KA].join(",")
  },
  {
    label: FORM_CODE_ENUM.FC_8K,
    value: [
      FORM_CODE_ENUM.FC_8K,
      FORM_CODE_ENUM.FC_8KA,
      FORM_CODE_ENUM.FC_8K12B
    ].join(",")
  },
  {
    label: FORM_CODE_ENUM.FC_10K,
    value: [FORM_CODE_ENUM.FC_10K, FORM_CODE_ENUM.FC_10KA].join(",")
  },
  { label: FORM_CODE_ENUM.FC_10Q, value: [FORM_CODE_ENUM.FC_10Q].join(",") },
  {
    label: "DEF-14A",
    value: [
      FORM_CODE_ENUM.FC_DEF14A,
      FORM_CODE_ENUM.FC_DEFA14A,
      FORM_CODE_ENUM.FC_PRE14A
    ].join(",")
  },
  {
    label: formPrefix + FORM_CODE_ENUM.FC_3,
    value: [FORM_CODE_ENUM.FC_3].join(",")
  },
  {
    label: formPrefix + FORM_CODE_ENUM.FC_4,
    value: [FORM_CODE_ENUM.FC_4].join(",")
  },
  {
    label: formPrefix + FORM_CODE_ENUM.FC_5,
    value: [FORM_CODE_ENUM.FC_5].join(",")
  }
];

export const FORM_TYPE_ENUM: {
  FT_10: "form10";
  FT_14: "form14";
} = {
  FT_10: "form10",
  FT_14: "form14"
};

type FORM_10_ENUM = Pick<
  PV_DISCLOSURE_FORM_CODE,
  "FC_10K" | "FC_10KA" | "FC_10Q"
>;
export type PV_DISCLOSURE_FORM_10_CODES_KEY =
  PV_DISCLOSURE_FORM_CODE[keyof FORM_10_ENUM];

export const FORM_10_CODES: Array<PV_DISCLOSURE_FORM_10_CODES_KEY> = [
  FORM_CODE_ENUM.FC_10K,
  FORM_CODE_ENUM.FC_10KA,
  FORM_CODE_ENUM.FC_10Q
];

export type PV_DISCLOSURE_FORM_14_CODES_KEY =
  PV_DISCLOSURE_FORM_CODE[keyof Pick<
    PV_DISCLOSURE_FORM_CODE,
    "FC_DEF14A" | "FC_DEFA14A"
  >];

export const FORM_14_CODES: Array<PV_DISCLOSURE_FORM_14_CODES_KEY> = [
  FORM_CODE_ENUM.FC_DEF14A,
  FORM_CODE_ENUM.FC_DEFA14A
];

const FORM_TYPE_FORM_10 = FORM_10_CODES.reduce((acc, v) => {
  acc[v] = "form10";
  return acc;
}, {} as { [key1 in PV_DISCLOSURE_FORM_10_CODES_KEY]: "form10" });
const FORM_TYPE_FORM_14 = FORM_14_CODES.reduce((acc, v) => {
  acc[v] = "form14";
  return acc;
}, {} as { [key1 in PV_DISCLOSURE_FORM_14_CODES_KEY]: "form14" });

type T_CODE_TYPE_MAP = {
  [key1 in PV_DISCLOSURE_FORM_10_CODES_KEY]: "form10";
} & {
  [key2 in PV_DISCLOSURE_FORM_14_CODES_KEY]: "form14";
};

export type PV_DISCLOSURE_HANDLE_FORM_TYPE =
  | PV_DISCLOSURE_FORM_10_CODES_KEY
  | PV_DISCLOSURE_FORM_14_CODES_KEY;
export const FORM_CODE_TO_TYPE_MAP: T_CODE_TYPE_MAP = {
  ...FORM_TYPE_FORM_10,
  ...FORM_TYPE_FORM_14
};

export const FORM_TYPES = [FORM_TYPE_ENUM.FT_10, FORM_TYPE_ENUM.FT_14];

export const FORM_DETAIL_SUPPORT_CODES = [...FORM_10_CODES, ...FORM_14_CODES];

export const FORM_DESCRIPTION = {
  [FORM_CODE_ENUM.FC_DEF14A]:
    "DEF 14A는 기업이 주주에게 제출하는 공식 문서 중 하나로, 주주총회를 위한 전체 이사회의 공동 결의 및 의사결정을 전달합니다. 이 문서는 주주총회의 일정에 관한 정보, 이사회 후보자들의 경력 및 보수에 대한 정보, 이사회의 구성 및 위원회 구성, 이사회 결의, 이사회의 보수 정책, 그리고 투표 안건에 대한 정보 등을 포함하고 있습니다.따라서, DEF 14A는 기업의 주주들이 주주총회에서 결정할 사항에 대해 잘 이해하고 투표할 수 있도록 필수적인 문서입니다."
};

// ## form detail type ## //

export type PV_FORM_10_KEYS = {
  key: keyof Partial<PV_DISCLOSURE_10_FORM_DETAIL>;
  type: 1 | 2 | 3;
  title: string;
};
export const FORM_10_DETAIL_SETTING: Array<PV_FORM_10_KEYS> = [
  { key: "business", type: 1, title: "사업 개요 (Business)" },
  { key: "risk", type: 3, title: "위험 요소 (Risk)" },
  { key: "legal", type: 2, title: "법적 이슈사항 (Legal)" },
  { key: "repurchase", type: 1, title: "자사주 매입 현황 (Repurchase)" },
  { key: "finance", type: 2, title: "재무제표 (Finance)" }
];

type PV_DISCLOSURE_FORM_10_PROPS =
  | {
      fieldKey: keyof Partial<
        Pick<VM_DISCLOSURE_10_FORM, "businessOverviewKor">
      >;
      key: "business";
      type: "string";
    }
  | {
      fieldKey: keyof Partial<Pick<VM_DISCLOSURE_10_FORM, "riskJSONKor">>;
      key: "risk";
      type: "json";
    }
  | {
      fieldKey: keyof Partial<Pick<VM_DISCLOSURE_10_FORM, "legalJSONKor">>;
      key: "legal";
      type: "json";
    }
  | {
      fieldKey: keyof Partial<Pick<VM_DISCLOSURE_10_FORM, "repurchaseKor">>;
      key: "repurchase";
      type: "string";
    }
  | {
      fieldKey: keyof Partial<
        Pick<
          VM_DISCLOSURE_10_FORM,
          | "financeEarningKor"
          | "financeLiquidityKor"
          | "financeEquityKor"
          | "financeOthersKor"
        >
      >;
      key: "finance";
      type: "manual";
    };

export const FORM_10_FIELD_SETTING: Array<PV_DISCLOSURE_FORM_10_PROPS> = [
  { fieldKey: "businessOverviewKor", key: "business", type: "string" },

  { fieldKey: "riskJSONKor", key: "risk", type: "json" },

  { fieldKey: "legalJSONKor", key: "legal", type: "json" },

  { fieldKey: "repurchaseKor", key: "repurchase", type: "string" },

  { fieldKey: "financeEarningKor", key: "finance", type: "manual" },
  { fieldKey: "financeLiquidityKor", key: "finance", type: "manual" },
  { fieldKey: "financeEquityKor", key: "finance", type: "manual" },
  { fieldKey: "financeOthersKor", key: "finance", type: "manual" }
];
