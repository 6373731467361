import "./index.css";

export default function InputLabel({ labelText }: { labelText: string }) {
  return (
    <div className="atomic atoms input-label">
      {labelText.split("").map((v, i) => (
        <div key={i}>
          <span>{v}</span>
        </div>
      ))}
    </div>
  );
}
