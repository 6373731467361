import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";

const CATEGORY_SHANGHAI_MASTER = [
  {
    value: "定期报告",
    kor: "정기보고서",
    org: "定期报告"
  },
  {
    value: "董事会和监事会",
    kor: "이사회/감사보고",
    org: "董事会和监事会"
  },
  {
    value: "股东大会",
    kor: "주주총회",
    org: "股东大会"
  },
  {
    value: "应当披露的交易",
    kor: "공시의무거래",
    org: "应当披露的交易"
  },
  {
    value: "首次公开发行",
    kor: "기업공개",
    org: "首次公开发行"
  },
  {
    value: "关联交易",
    kor: "특수관계자거래",
    org: "关联交易"
  },
  {
    value: "对外担保",
    kor: "보증",
    org: "对外担保"
  },
  {
    value: "募集资金使用与管理",
    kor: "재무",
    org: "募集资金使用与管理"
  },
  {
    value: "业绩预告、业绩快报和盈利预测",
    kor: "실적",
    org: "业绩预告、业绩快报和盈利预测"
  },
  {
    value: "利润分配和资本公积金转增股本",
    kor: "배당/이익분배",
    org: "利润分配和资本公积金转增股本"
  },
  {
    value: "股票交易异常波动和澄清",
    kor: "이상거래",
    org: "股票交易异常波动和澄清"
  },
  {
    value: "股份上市流通与股本变动",
    kor: "보호예수해제",
    org: "股份上市流通与股本变动"
  },
  {
    value: "股东增持或减持股份",
    kor: "지분변동",
    org: "股东增持或减持股份"
  },
  {
    value: "权益变动报告书和（要约）收购",
    kor: "지분/자본금변동",
    org: "权益变动报告书和（要约）收购"
  },
  {
    value: "股权型再融资",
    kor: "주식관련사채권",
    org: "股权型再融资"
  },
  {
    value: "其他再融资",
    kor: "채권발행",
    org: "其他再融资"
  },
  {
    value: "重大资产重组",
    kor: "중대지분변경",
    org: "重大资产重组"
  },
  {
    value: "吸收合并",
    kor: "흡수합병",
    org: "吸收合并"
  },
  {
    value: "回购股份",
    kor: "자사주매입",
    org: "回购股份"
  },
  {
    value: "可转换公司债",
    kor: "전환사채",
    org: "可转换公司债"
  },
  {
    value: "股权激励及员工持股计划",
    kor: "스톡옵션/우리사주",
    org: "股权激励及员工持股计划"
  },
  {
    value: "诉讼和仲裁",
    kor: "소송/중재",
    org: "诉讼和仲裁"
  },
  {
    value: "股东股份被质押冻结或司法拍卖",
    kor: "주식담보",
    org: "股东股份被质押冻结或司法拍卖"
  },
  {
    value: "破产与重整",
    kor: "파산/구조조정",
    org: "破产与重整"
  },
  {
    value: "其他重大事项",
    kor: "기타주요공시",
    org: "其他重大事项"
  },
  {
    value: "公司重要基本信息变化",
    kor: "경영주요공시",
    org: "公司重要基本信息变化"
  },
  {
    value: "风险警示",
    kor: "위험경고",
    org: "风险警示"
  },
  {
    value: "暂停、恢复和终止上市",
    kor: "상장폐지",
    org: "暂停、恢复和终止上市"
  },
  {
    value: "补充更正公告",
    kor: "정정공시",
    org: "补充更正公告"
  },
  {
    value: "规范运作",
    kor: "성과/주요사항",
    org: "规范运作"
  },
  {
    value: "中介机构报告",
    kor: "외부감사보고",
    org: "中介机构报告"
  },
  {
    value: "停复牌提示性公告",
    kor: "거래중단/재개",
    org: "停复牌提示性公告"
  },
  {
    value: "优先股",
    kor: "우선주발행/배당",
    org: "优先股"
  },
  {
    value: "特别表决权",
    kor: "특별의결권",
    org: "特别表决权"
  },
  {
    value: "超额配售选择权",
    kor: "초과배당",
    org: "超额配售选择权"
  },
  {
    value: "存托凭证相关公告",
    kor: "예탁증서",
    org: "存托凭证相关公告"
  },
  {
    value: "询价转让及配售",
    kor: "지분구조변경",
    org: "询价转让及配售"
  },
  {
    value: "境内外同步披露",
    kor: "해외공시정보",
    org: "境内外同步披露"
  },
  {
    value: "其他",
    kor: "기타경영정보",
    org: "其他"
  },
  {
    value: "年报",
    kor: "연간보고서",
    org: "年报"
  },
  {
    value: "一季报",
    kor: "1분기보고서",
    org: "一季报"
  },
  {
    value: "三季报",
    kor: "3분기보고서",
    org: "三季报"
  },
  {
    value: "半年报",
    kor: "2분기보고서",
    org: "半年报"
  }
];

export type PV_CATEGORY_MAPPING_SHANGHAI_KEY =
  keyof typeof CATEGORY_MAPPING_SHANGHAI;

export const CATEGORY_MAPPING_SHANGHAI = CATEGORY_SHANGHAI_MASTER.reduce(
  (acc, cur) => {
    acc[cur.value] = cur;
    return acc;
  },
  {} as {
    [key in string]: { kor: string; value: string; org: string };
  }
);
const CATEGORY_MAPPING_SHANGHAI_MAP = new Map<string, Array<string>>();
Object.keys(CATEGORY_MAPPING_SHANGHAI).forEach((v) => {
  const key = v as PV_CATEGORY_MAPPING_SHANGHAI_KEY;
  const value = CATEGORY_MAPPING_SHANGHAI[key].kor;
  const list =
    CATEGORY_MAPPING_SHANGHAI_MAP.get(CATEGORY_MAPPING_SHANGHAI[key].kor) ?? [];
  if (list.length === 0) {
    CATEGORY_MAPPING_SHANGHAI_MAP.set(value, list);
  }
  list.push(v);
});

export const SHANGHAI_CATEGORY_OPTIONS: Array<PV_SELECT_OPTION<Array<string>>> =
  [...CATEGORY_MAPPING_SHANGHAI_MAP.keys()]
    .sort((a, b) => a.localeCompare(b))
    .map((key) => {
      const targetValue = CATEGORY_MAPPING_SHANGHAI_MAP.get(key) ?? [];

      return {
        shortLabel: key,
        longLabel: targetValue.join(", "),
        resultLabel: key,
        value: targetValue
      };
    });
