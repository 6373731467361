import { FadeLoader } from "react-spinners";

export default function Spiner() {
  return (
    <div
      className="center-box"
      style={{ position: "absolute", top: "0", left: "0" }}
    >
      <FadeLoader color="#999999" />
    </div>
  );
}
