import { useContentFontStore } from "@/components/_store/zustand/useContentFontStore";
import { usePrintStore } from "@/components/_store/zustand/usePrintStore";
import { useSelectItemStore } from "@/components/_store/zustand/useSelectItemStore";
import MinusBtn from "@/components/atomic/atoms/SimplePart/MinusBtn";
import PlusBtn from "@/components/atomic/atoms/SimplePart/PlusBtn";
import PrintBtn from "@/components/atomic/atoms/SimplePart/PrintBtn";
import RefreshBtn from "@/components/atomic/atoms/SimplePart/RefreshBtn";
import Seperator from "@/components/atomic/atoms/SimplePart/Seperator";
import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_DISCLOSURE_ITEM } from "@/types/PresenterModel/PV_DISCLOSURE_ITEM";
import {
  AMP_CLICK_DETAIL_FONT_SIZE_DOWN_BTN,
  AMP_CLICK_DETAIL_FONT_SIZE_RESET_BTN,
  AMP_CLICK_DETAIL_FONT_SIZE_UP_BTN,
  AMP_CLICK_DETAIL_ORIGIN_PAGE_BTN,
  AMP_CLICK_DETAIL_PRINT_BTN,
  logEvent
} from "@/utils/logEvent";
import { ErrorBoundary } from "@sentry/react";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import _ from "lodash";
import { useCallback } from "react";
import { ENUM_TIMEZONE } from "../../../../constants/ENUM_TIMEZONE";
import { formTags, padCategoryCode } from "../../../../utils/Fomatter";
import "./index.css";

const consumer = _.debounce((fn: () => void) => {
  fn();
}, 300);

function LocalTime({ selectedItem }: { selectedItem: PV_DISCLOSURE_ITEM }) {
  return format(new Date(selectedItem.dataDate), "yyyy/MM/dd HH:mm:ss");
}

function OriginalTime({ selectedItem }: { selectedItem: PV_DISCLOSURE_ITEM }) {
  return (
    <>
      {` (현지기준 
    ${formatInTimeZone(
      selectedItem.dataDate,
      ENUM_TIMEZONE[selectedItem.exchangeType],
      "yyyy/MM/dd HH:mm:ss"
    )})`}
    </>
  );
}

export default function ContentTitleBox() {
  const selectedItem = useSelectItemStore((state) => state.item);

  const fontSize = useContentFontStore((state) =>
    state.actions.getCurrentFontSize()
  );
  const canSizeUp = useContentFontStore((state) =>
    state.actions.getCanSizeUp()
  );
  const canSizeDown = useContentFontStore((state) =>
    state.actions.getCanSizeDown()
  );
  const canSizeRefresh = useContentFontStore((state) =>
    state.actions.getCanSizeRefresh()
  );

  const handleOnSizeUp = useCallback(() => {
    logEvent(AMP_CLICK_DETAIL_FONT_SIZE_UP_BTN, {
      fontSize
    });
    fontActions.sizeUp();
  }, [fontSize]);

  const handleOnSizeDown = useCallback(() => {
    logEvent(AMP_CLICK_DETAIL_FONT_SIZE_DOWN_BTN, {
      fontSize
    });
    fontActions.sizeDown();
  }, [fontSize]);

  const handleOnResetBtn = useCallback(() => {
    logEvent(AMP_CLICK_DETAIL_FONT_SIZE_RESET_BTN, {
      fontSize
    });
    fontActions.reset();
  }, [fontSize]);

  const needPrint = usePrintStore((state) => state.state.needPrint);
  const fontActions = useContentFontStore((state) => state.actions);
  const printActions = usePrintStore((state) => state.actions);

  const handleOriginPageClick = useCallback(() => {
    logEvent(AMP_CLICK_DETAIL_ORIGIN_PAGE_BTN, {
      originURL: selectedItem?.selectedItem.fileUrl,
      filingId: selectedItem?.selectedItem.id
    });
  }, []);

  const handlePrint = useCallback(() => {
    consumer(() => {
      if (!needPrint) {
        logEvent(AMP_CLICK_DETAIL_PRINT_BTN, {
          filingId: selectedItem?.selectedItem.id
        });
        printActions.setNeedPrint(true);
      }
    });
  }, [printActions, needPrint]);

  return (
    <div id="contentTitleBox" style={{ fontSize }}>
      <div id="title">
        {selectedItem?.selectedItem.categoryName && (
          <div id="formName">
            <div className="button">
              <span>{selectedItem?.selectedItem.categoryName}</span>
            </div>
          </div>
        )}
        <span id="titleText">{selectedItem?.selectedItem.summaryShort}</span>
      </div>
      <div id="subtitle">
        <div id="subtitleText">
          <span>
            {selectedItem?.selectedItem.dataDate ? (
              <>
                <LocalTime selectedItem={selectedItem.selectedItem} />
                <ErrorBoundary>
                  <OriginalTime selectedItem={selectedItem.selectedItem} />
                </ErrorBoundary>
              </>
            ) : (
              "-"
            )}
          </span>

          {selectedItem?.selectedItem.categoryCode && (
            <>
              <Seperator
                style={{
                  height: fontSize * 0.7,
                  marginLeft: fontSize * 0.5,
                  marginRight: fontSize * 0.5
                }}
              />
              <span>
                {selectedItem.selectedItem.exchangeType !==
                GQL_EXCHANGE_TYPE.USA ? (
                  <>
                    {padCategoryCode(selectedItem?.selectedItem.categoryCode)}
                  </>
                ) : (
                  <b>
                    {padCategoryCode(selectedItem?.selectedItem.categoryCode)}
                  </b>
                )}
              </span>
            </>
          )}

          {selectedItem?.selectedItem.tags &&
          selectedItem?.selectedItem.tags.length > 0 ? (
            <>
              <Seperator
                style={{
                  height: fontSize * 0.7,
                  marginLeft: fontSize * 0.5,
                  marginRight: fontSize * 0.5
                }}
              />
              <span>{formTags(selectedItem?.selectedItem.tags)}</span>
            </>
          ) : (
            ""
          )}
        </div>
        <div id="controlBox">
          <PlusBtn
            className="Button"
            onClick={handleOnSizeUp}
            disabled={!canSizeUp}
          />
          <MinusBtn
            className="Button"
            onClick={handleOnSizeDown}
            disabled={!canSizeDown}
          />
          <RefreshBtn
            className="Button"
            onClick={handleOnResetBtn}
            disabled={!canSizeRefresh}
          />
          <PrintBtn className="Button" onClick={handlePrint} />
          {selectedItem?.selectedItem.fileUrl ? (
            <a
              className="org-doc-button Button"
              href={selectedItem?.selectedItem.fileUrl}
              rel="noopener noreferrer"
              download
              target="_blank"
              onClick={handleOriginPageClick}
            >
              원문페이지 조회
            </a>
          ) : (
            <p className="org-doc-button">원문페이지 조회</p>
          )}
        </div>
      </div>
    </div>
  );
}
