import "normalize.css";
import "reset-css";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";

import {
  ExtraErrorData,
  HttpClient as HttpClientIntegration
} from "@sentry/integrations";
import { Breadcrumbs, BrowserTracing, init as SentryInit } from "@sentry/react";

import "./index.css";

import {
  Identify,
  identify,
  init as initAmplitude
} from "@amplitude/analytics-browser";

if (import.meta.env.VITE_SENTRY_DSN_KEY) {
  const environment = import.meta.env.MODE || "unknown";
  const release =
    import.meta.env.VITE_PACKAGE_NAME && import.meta.env.VITE_PACKAGE_VERSION
      ? import.meta.env.VITE_PACKAGE_NAME +
        "@" +
        import.meta.env.VITE_PACKAGE_VERSION
      : "unknown@unknown";

  SentryInit({
    environment,
    release,
    dsn: import.meta.env.VITE_SENTRY_DSN_KEY,
    integrations: [
      new Breadcrumbs(),
      new BrowserTracing(),
      new ExtraErrorData(),
      new HttpClientIntegration({
        // This array can contain tuples of `[begin, end]` (both inclusive),
        // single status codes, or a combination of both.
        // default: [[500, 599]]
        failedRequestStatusCodes: [[400, 599]],

        // This array can contain Regexes, strings, or a combination of both.
        // default: [/.*/]
        failedRequestTargets: [import.meta.env.VITE_API_SERVER_URL]
      })
    ],

    tracesSampleRate: import.meta.env.MODE === "production" ? 0.1 : 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if (import.meta.env.VITE_AMPLITUDE_KEY) {
  initAmplitude(import.meta.env.VITE_AMPLITUDE_KEY);
  const event = new Identify();
  identify(event);
}
