import { useUiConditionStore } from "@/components/_store/zustand/useUiConditionStore";
import useRQSecurityCatalog from "@/hook/RQ/useRQSecurityCatalog";

export const useSecurityCatalog = () => {
  const securityCode = useUiConditionStore((state) =>
    (state.securityCode.keyword ?? "").toUpperCase()
  );
  const exchangeType = useUiConditionStore((state) =>
    state.exchangeCode.selected?.value
      ? state.exchangeCode.selected?.value
      : undefined
  );

  const { data, isInitialLoading } = useRQSecurityCatalog({
    securityCode: securityCode,
    exchangeType
  });

  return {
    data: data ?? [],
    isLoading: isInitialLoading
  };
};
