import { reqHanoihDisclosureList } from "@/api/fetchDisclosureList/HanoiDisclosureFetcher";
import { reqHochiminhDisclosureList } from "@/api/fetchDisclosureList/HochiminhDisclosureFetcher";
import { reqHongkongDisclosureList } from "@/api/fetchDisclosureList/HongkongDisclosureFetcher";
import { reqJapanDisclosureList } from "@/api/fetchDisclosureList/JapanDisclosureFetcher";
import { reqShanghaiDisclosureList } from "@/api/fetchDisclosureList/ShanhaiDisclosureFetcher";
import { reqShenzhenDisclosureList } from "@/api/fetchDisclosureList/ShenzhenDisclosureFetcher";
import { reqUSADisclosureList } from "@/api/fetchDisclosureList/USADisclosureFetcher";
import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_DISCLOSURE_PAGE } from "@/types/PresenterModel/PV_DISCLOSURE_PAGE";
import { API_REQ_DISCLOSURE_LIST } from "@/types/apiModel/Disclosure/API_REQ_DISCLOSURE_LIST";
import { AMP_REQUEST_LIST_SEARCH, logEvent } from "@/utils/logEvent";

export default async function fetchSvrDisclosure(
  params: API_REQ_DISCLOSURE_LIST
): Promise<PV_DISCLOSURE_PAGE> {
  logEvent(AMP_REQUEST_LIST_SEARCH, params);

  if (params.exchangeType === GQL_EXCHANGE_TYPE.USA) {
    return reqUSADisclosureList(params);
  }
  if (params.exchangeType === GQL_EXCHANGE_TYPE.SHENZHEN) {
    return reqShenzhenDisclosureList(params);
  }
  if (params.exchangeType === GQL_EXCHANGE_TYPE.HONGKONG) {
    return reqHongkongDisclosureList(params);
  }
  if (params.exchangeType === GQL_EXCHANGE_TYPE.SHANGHAI) {
    return reqShanghaiDisclosureList(params);
  }
  if (params.exchangeType === GQL_EXCHANGE_TYPE.JAPAN) {
    return reqJapanDisclosureList(params);
  }
  if (params.exchangeType === GQL_EXCHANGE_TYPE.HOCHIMINH) {
    return reqHochiminhDisclosureList(params);
  }
  if (params.exchangeType === GQL_EXCHANGE_TYPE.HANOI) {
    return reqHanoihDisclosureList(params);
  }

  return {
    list: [],
    exchangeType: params.exchangeType,
    pageNumber: params.page
  };
}
