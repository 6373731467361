import { parseHochiminhListItem } from "@/api/fetchDisclosureList/HochiminhDisclosureFetcher/parseHochiminhListItem";
import { isDisclosureListItem } from "@/api/fetchDisclosureList/common/isDisclosureListItem";
import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_DISCLOSURE_PAGE } from "@/types/PresenterModel/PV_DISCLOSURE_PAGE";
import { API_REQ_DISCLOSURE_LIST } from "@/types/apiModel/Disclosure/API_REQ_DISCLOSURE_LIST";
import { API_RES_DISCLOSURE_HOCHIMINH } from "@/types/apiModel/Disclosure/API_RES_DISCLOSURE_HOCHIMINH";
import createGraphqlClient from "@/utils/createGraphqlClient";
import { gql } from "graphql-request";

const query = (p: API_REQ_DISCLOSURE_LIST) => gql`
query disclosureListHochiminh {
  getDisclosure(
    filter: {
      exchangeType: HOCHIMINH
      ${p.includeTinyKor ? `includeTinyKor : "${p.includeTinyKor}"` : ""}
      ${p.securityCode ? `securityCode: "${p.securityCode}"` : ""}
      limit: 100
      page: ${p.page}
      timeRange: { 
        ${`startDate: "${p.startDate}"`}
        ${`endDate: "${p.endDate}"`}
      }
  }) {
    id
    filingDate
    korName
    details {
      ... on Hochiminh {
        secCode
        fileLink
        isImage
      }
    }
    analysisDetails {
      topicKor
      summarizeTinyKor
      summarizeLongKor
    }
  }
}
`;

function parser(
  data: API_RES_DISCLOSURE_HOCHIMINH,
  pageNumber: number
): PV_DISCLOSURE_PAGE {
  const list = (data?.getDisclosure || [])
    .map((v) => parseHochiminhListItem(v, GQL_EXCHANGE_TYPE.HOCHIMINH))
    .filter(isDisclosureListItem);

  return {
    list,
    pageNumber,
    exchangeType: GQL_EXCHANGE_TYPE.HOCHIMINH
  };
}

export async function reqHochiminhDisclosureList(p: API_REQ_DISCLOSURE_LIST) {
  const client = createGraphqlClient();
  return parser(await client.request(query(p)), p.page);
}
