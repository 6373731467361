import {
  SYS_ZUSTAND_GET,
  SYS_ZUSTAND_SET
} from "@/shared/common/types/zustand/common";
import { create } from "zustand";

const fontSizeArray = [13, 14, 16, 19, 24];

/** alias **/
type FIELD = {
  options: {
    fontSizeIndex: number;
  };
};

type STORE = FIELD & { actions: INTERFACE };

const initField = (): FIELD => {
  return {
    options: {
      fontSizeIndex: 0
    }
  };
};

const sizeUp = (set: SYS_ZUSTAND_SET<STORE>, get: SYS_ZUSTAND_GET<STORE>) => {
  const currentFontSizeIndex = get().options.fontSizeIndex;
  if (currentFontSizeIndex < fontSizeArray.length - 1) {
    set({ options: { fontSizeIndex: currentFontSizeIndex + 1 } });
  }
};

const sizeDown = (set: SYS_ZUSTAND_SET<STORE>, get: SYS_ZUSTAND_GET<STORE>) => {
  const currentFontSizeIndex = get().options.fontSizeIndex;
  if (currentFontSizeIndex > 0) {
    set({ options: { fontSizeIndex: currentFontSizeIndex - 1 } });
  }
};

type INTERFACE = {
  getCurrentFontSize: () => number;
  getCanSizeUp: () => boolean;
  getCanSizeDown: () => boolean;
  getCanSizeRefresh: () => boolean;
  sizeUp: () => void;
  sizeDown: () => void;
  reset: () => void;
};

const baseInfoInterface = (
  set: SYS_ZUSTAND_SET<STORE>,
  get: SYS_ZUSTAND_GET<STORE>
): INTERFACE => {
  return {
    getCurrentFontSize: () => fontSizeArray[get().options.fontSizeIndex],
    getCanSizeUp: () => get().options.fontSizeIndex < fontSizeArray.length - 1,
    getCanSizeDown: () => get().options.fontSizeIndex > 0,
    getCanSizeRefresh: () => get().options.fontSizeIndex !== 0,
    sizeUp: () => sizeUp(set, get),
    sizeDown: () => sizeDown(set, get),
    reset: () => set(initField())
  };
};

export const useContentFontStore = create<STORE>((set, get) => ({
  ...initField(),
  actions: {
    ...baseInfoInterface(set, get)
  }
}));
