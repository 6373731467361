import { isDisclosureListItem } from "@/api/fetchDisclosureList/common/isDisclosureListItem";
import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_DISCLOSURE_PAGE } from "@/types/PresenterModel/PV_DISCLOSURE_PAGE";
import { API_REQ_DISCLOSURE_LIST } from "@/types/apiModel/Disclosure/API_REQ_DISCLOSURE_LIST";
import { API_RES_DISCLOSURE_HONGKONG } from "@/types/apiModel/Disclosure/API_RES_DISCLOSURE_HONGKONG";
import createGraphqlClient from "@/utils/createGraphqlClient";
import { gql } from "graphql-request";
import { parseHongkongListItem } from "./parseHongkongListItem";

const query = (p: API_REQ_DISCLOSURE_LIST) => gql`
query disclosureListHongkong {
  getDisclosure(
    filter: {
      exchangeType: HONGKONG
      ${p.includeTinyKor ? `includeTinyKor : "${p.includeTinyKor}"` : ""}
      ${p.securityCode ? `securityCode: "${p.securityCode}"` : ""}
      ${p.categoryId ? `categoryId: ${p.categoryId}` : ""}
      limit: 100
      page: ${p.page}
      timeRange: { 
        ${`startDate: "${p.startDate}"`}
        ${`endDate: "${p.endDate}"`}
      }
  }) {
    id
    dataDate
    korName
    details {
      ... on Hongkong {
        secName
        secCode
        categoryId
        fileLink
      }
    }
    analysisDetails {
      topicKor
      summarizeTinyKor
      summarizeLongKor
    }
  }
}

`;

function parser(
  data: API_RES_DISCLOSURE_HONGKONG,
  pageNumber: number
): PV_DISCLOSURE_PAGE {
  const list = (data?.getDisclosure || [])
    .map((v) => parseHongkongListItem(v, GQL_EXCHANGE_TYPE.HONGKONG))
    .filter(isDisclosureListItem);

  return {
    list,
    pageNumber,
    exchangeType: GQL_EXCHANGE_TYPE.HONGKONG
  };
}

export async function reqHongkongDisclosureList(p: API_REQ_DISCLOSURE_LIST) {
  const client = createGraphqlClient();
  return parser(await client.request(query(p)), p.page);
}
