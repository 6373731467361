import { useUiConditionStore } from "@/components/_store/zustand/useUiConditionStore";
import SelectControl from "@/components/atomic/organisms/SelectControl";
import useCategoryCodeList from "@/hook/useCategoryCodeList";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";
import _ from "lodash";
import { useEffect, useState } from "react";

const CLEAR_ITEM: PV_SELECT_OPTION<string[]> = {
  longLabel: "",
  shortLabel: "전체",
  value: []
};

const consumer = _.debounce((fn: () => void) => {
  fn();
}, 200);

export default function CategoryCode() {
  const selectItem = useUiConditionStore(
    (state) => (v: PV_SELECT_OPTION<Array<string>> | null) => {
      const item = v && v.value && v.value.length > 0 ? v : null;
      state.actions.selectItem("categoryType", item);
    },
    () => true
  );

  const nationCode = useUiConditionStore(
    (state) => state.exchangeCode.selected?.value ?? null
  );

  const selectedItem = useUiConditionStore(
    (state) => state.categoryType.selected
  );

  const { list: categoryOptionList } = useCategoryCodeList(nationCode);

  const [list, setList] = useState<PV_SELECT_OPTION<string[]>[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (nationCode) {
      selectItem(null);
    }
    if (!categoryOptionList || categoryOptionList.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [categoryOptionList]);

  useEffect(() => {
    consumer(() => {
      setList([...categoryOptionList]);
    });
  }, [categoryOptionList]);

  return (
    <SelectControl<Array<string>>
      disabled={disabled}
      labelText="공시유형"
      onSelect={selectItem}
      selectedItem={selectedItem}
      list={[CLEAR_ITEM, ...list]}
      dataType="categoryType"
      // selectorFontClass="font-medium space-small"
      // resultFontClass="font-medium space-small"
    />
  );
}
