import {
  GQL_ENUM_EXCHANGE_TYPE,
  GQL_EXCHANGE_TYPE
} from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
export const ORG_DOC_WHITE_LIST_DOMAIN_REGX_MAP: {
  [key in GQL_ENUM_EXCHANGE_TYPE]: RegExp;
} = {
  [GQL_EXCHANGE_TYPE.USA]: /^(http(s)?):\/\/(www\.sec\.gov)/,
  [GQL_EXCHANGE_TYPE.HANOI]: /^(http(s)?):\/\/(owa\.hnx\.vn)/,
  [GQL_EXCHANGE_TYPE.HOCHIMINH]: /^(http(s)?):\/\/(www\.hsx\.vn)/,
  [GQL_EXCHANGE_TYPE.HONGKONG]: /^(http(s)?):\/\/(www1\.hkexnews\.hk)/,
  [GQL_EXCHANGE_TYPE.JAPAN]:
    /^(http(s)?):\/\/(disclosure2\.edinet-fsa\.go\.jp)/,
  [GQL_EXCHANGE_TYPE.SHANGHAI]: /^(http(s)?):\/\/(www\.sse\.com\.cn)/,
  [GQL_EXCHANGE_TYPE.SHENZHEN]: /^(http(s)?):\/\/(www\.szse\.cn)/
};
