import {
  SYS_ZUSTAND_GET,
  SYS_ZUSTAND_SET
} from "@/shared/common/types/zustand/common";
import { API_REQ_DISCLOSURE_LIST } from "@/types/apiModel/Disclosure/API_REQ_DISCLOSURE_LIST";
import { format } from "date-fns";
import sub from "date-fns/sub";
import _ from "lodash";
import { create } from "zustand";

export type PV_FILINGS_SEARCH_COND_ITEM = Omit<
  API_REQ_DISCLOSURE_LIST,
  "page"
> & { version: string };

const isValidOptions = (
  params: Partial<PV_FILINGS_SEARCH_COND_ITEM>
): params is PV_FILINGS_SEARCH_COND_ITEM => {
  return Boolean(params.startDate && params.endDate);
};
type STORE = FIELD & { actions: INTERFACE };

/** alias **/
type FIELD = {
  options: PV_FILINGS_SEARCH_COND_ITEM;
};

const initField = (): FIELD => {
  return {
    options: {
      version: "",
      exchangeType: null,
      startDate: "",
      endDate: ""
    }
  };
};

const getDefaultParams = (
  p: Partial<PV_FILINGS_SEARCH_COND_ITEM>
): Partial<PV_FILINGS_SEARCH_COND_ITEM> => {
  const newParams: Partial<PV_FILINGS_SEARCH_COND_ITEM> = {
    ...p,
    version: _.uniqueId()
  };

  const today = new Date();

  if (!newParams.startDate)
    newParams.startDate = format(sub(today, { years: 1 }), "yyyy-MM-dd");
  else newParams.startDate = newParams.startDate.replace(/\//g, "-");

  if (!newParams.endDate) newParams.endDate = format(today, "yyyy-MM-dd");
  else newParams.endDate = newParams.endDate.replace(/\//g, "-");

  return newParams;
};

const setCondition = (
  set: SYS_ZUSTAND_SET<STORE>,
  params: Partial<PV_FILINGS_SEARCH_COND_ITEM>
) => {
  const options: Partial<PV_FILINGS_SEARCH_COND_ITEM> =
    getDefaultParams(params);
  if (isValidOptions(options)) set({ options });
};

type INTERFACE = {
  setCondition: (params: Partial<PV_FILINGS_SEARCH_COND_ITEM>) => void;
  getBefSummaryTiny: () => string;
  reset: () => void;
};

const initInterface = (
  set: SYS_ZUSTAND_SET<STORE>,
  get: SYS_ZUSTAND_GET<STORE>
): INTERFACE => {
  return {
    setCondition: (params) => setCondition(set, params),
    getBefSummaryTiny: () => get().options.includeTinyKor || "",
    reset: () => set(initField())
  };
};

export const useSearchConditionStore = create<STORE>((set, get) => ({
  ...initField(),
  actions: {
    ...initInterface(set, get)
  }
}));
