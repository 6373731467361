import NoData from "@/components/DisclosureList/TableBody/NoData";
import Row from "@/components/DisclosureList/TableBody/Row";
import Spiner from "@/components/DisclosureList/TableBody/Spiner";
import { useSearchConditionStore } from "@/components/_store/zustand/useSearchConditionStore";
import { useDisclosureList } from "@/hook/useDisclosureList";
import { PV_DISCLOSURE_PAGE } from "@/types/PresenterModel/PV_DISCLOSURE_PAGE";
import { InfiniteData } from "@tanstack/react-query";
import _ from "lodash";
import { useRef } from "react";
import "./index.css";

const consumer = _.throttle((fn: () => void) => {
  fn();
}, 500);

function hasData(data: InfiniteData<PV_DISCLOSURE_PAGE | null> | undefined) {
  return (
    data?.pages &&
    data?.pages.length > 0 &&
    (data.pages?.[0]?.list.length ?? 0) > 0
  );
}

export default function TableBody() {
  const exchangeType = useSearchConditionStore(
    (state) => state.options.exchangeType
  );

  const { isLoading, data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useDisclosureList();

  const containerRef = useRef<HTMLDivElement>(null);

  const paging = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    consumer(() => {
      if (hasNextPage && !isFetchingNextPage) {
        const target = e.target as HTMLDivElement;
        if (
          target.clientHeight + target.scrollTop + 601 >
          target.scrollHeight
        ) {
          if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
          }
        }
      }
    });
  };

  return (
    <div id="tableBody" onScroll={(e) => paging(e)} ref={containerRef}>
      {exchangeType && hasData(data) ? (
        data?.pages.map(
          (page) =>
            page?.list.map((v, i) => (
              <Row
                key={`${page.pageNumber}_${i}_${v.id}`}
                data={v}
                uniqueKey={`${page.pageNumber}_${i}`}
              />
            ))
        )
      ) : (
        <NoData />
      )}
      {isLoading && <Spiner />}
    </div>
  );
}
