type Props = {
  no?: number;
  title?: string;
  text: string;
};

export default function FlatForm({ no, title, text }: Props) {
  return (
    <div
      className={["content-part page-break", title ? "mb-1r" : ""].join(" ")}
    >
      {title && (
        <>
          <div className="title">
            <span>{title}</span>
          </div>
          <div className="body">
            <span>{text}</span>
          </div>
        </>
      )}
      {(no || no === 0) && (
        <span className="body">
          <strong>{no}.&nbsp;</strong>
          {text}
        </span>
      )}
    </div>
  );
}
