import InputLabel from "@/components/atomic/atoms/InputLabel";
import Calendar from "@/components/atomic/molecules/Calendar";
import "./index.css";

const compareDate = (v1: Date, v2: Date) => {
  if (v1.getFullYear() > v2.getFullYear()) {
    return 1;
  }
  if (v1.getFullYear() < v2.getFullYear()) {
    return -1;
  }

  if (v1.getMonth() > v2.getMonth()) {
    return 1;
  }
  if (v1.getMonth() < v2.getMonth()) {
    return -1;
  }

  if (v1.getDate() > v2.getDate()) {
    return 1;
  }
  if (v1.getDate() < v2.getDate()) {
    return -1;
  }

  return 0;
};

type Props = {
  labelText: string;
  startDate: string;
  endDate: string;
  onSelectStartDate: (date: Date | string) => void;
  onSelectEndDate: (date: Date | string) => void;
};

export default function DateControl({
  labelText,
  startDate,
  endDate,
  onSelectStartDate,
  onSelectEndDate
}: Props) {
  const startDateFilter = (v: Date | string) => {
    const newDate = new Date(v);
    if (isNaN(newDate.getTime())) return true;
    return compareDate(newDate, new Date(endDate)) <= 0;
  };
  const endDateFilter = (v: Date | string) => {
    const newDate = new Date(v);
    if (isNaN(newDate.getTime())) return true;
    return compareDate(newDate, new Date(startDate)) >= 0;
  };

  return (
    <div className="atomic organisms date-control">
      <div className="label-box">
        <InputLabel labelText={labelText} />
      </div>
      <div className="date-box">
        <Calendar
          date={startDate}
          onChange={onSelectStartDate}
          filterDate={startDateFilter}
          pos="datepicker-left-box"
        />
        <div>~</div>
        <Calendar
          date={endDate}
          onChange={onSelectEndDate}
          filterDate={endDateFilter}
          pos="datepicker-right-box"
        />
        <div id="datepicker-left-box" className="picker-box"></div>
        <div id="datepicker-right-box" className="picker-box"></div>
      </div>
    </div>
  );
}
