import SelectList from "@/components/atomic/atoms/SelectList";
import { useSelectListIndexStore } from "@/components/atomic/atoms/SelectList/SelectListIndexStore";
import DropDownBtn from "@/components/atomic/atoms/SimplePart/DropDownBtn";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions
} from "@floating-ui/react";
import _ from "lodash";
import {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import "./index.css";

const keyboardEventConsumer = _.throttle((fn: () => void) => {
  fn();
}, 30);

const openConsumer = _.throttle((fn: () => void) => {
  fn();
}, 100);

type Props<T> = {
  onSelect: (v: PV_SELECT_OPTION<T> | null) => void;
  list: Array<PV_SELECT_OPTION<T>>;
  className?: string;
  needClear?: boolean;
  dataType: string;
  selectorFontClass?: string;
  clearText: string;
  keyword: string;
  disabled?: boolean;
};

export default function Select<T = string>({
  className,
  onSelect,
  list,
  needClear,
  dataType,
  selectorFontClass,
  clearText,
  keyword,
  disabled
}: Props<T>) {
  const [ref, setRef] = useState<React.RefObject<HTMLUListElement> | null>(
    null
  );

  useEffect(() => {
    if (ref?.current) {
      const child = ref.current.children[0];
      if (child) {
        (child as HTMLLIElement)?.focus();
      }
    }
  }, [ref]);

  const [isOpen, setIsOpen] = useState(false);
  const store = useSelectListIndexStore((state) => state);

  const { y, refs, strategy, context } = useFloating<HTMLDivElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(), flip(), shift()],
    whileElementsMounted: autoUpdate
  });

  const { getReferenceProps } = useInteractions([useDismiss(context)]);

  const handleOnClick = useCallback(
    () =>
      openConsumer(() => {
        if (!disabled) {
          setIsOpen((prev) => !prev);
        }
      }),
    [isOpen, !!disabled]
  );

  const handleOnSelect = (v: PV_SELECT_OPTION<T> | null) => {
    onSelect(v);
    setIsOpen(false);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnKeydown: KeyboardEventHandler<
    HTMLInputElement | HTMLLIElement | HTMLDivElement
  > = (e) =>
    keyboardEventConsumer(() => {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        store.actions.subtractIdx();

        return;
      }

      if (e.key === "ArrowDown") {
        e.preventDefault();
        store.actions.addIdx();

        return;
      }

      if (e.key === "Enter") {
        const targetIdx = needClear ? store.idx - 1 : store.idx;
        if (targetIdx === -1) {
          onSelect(null);
        } else {
          onSelect(list[targetIdx]);
        }
        setIsOpen(false);
        return;
      }
    });

  return (
    <div
      className={[
        "atomic atoms select bordered",
        className,
        disabled ? "disabled" : ""
      ].join(" ")}
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      <div
        ref={inputRef}
        onClick={handleOnClick}
        className={["keyword", selectorFontClass].join(" ")}
      >
        <span>{keyword || clearText}</span>
      </div>
      <DropDownBtn onClick={handleOnClick} />
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y - 1,
            left: refs.domReference.current?.offsetLeft ?? 0 + 6,
            zIndex: "10"
          }}
        >
          <SelectList<T>
            setRef={setRef}
            onSelect={handleOnSelect}
            list={list}
            clearText={clearText}
            needClear={needClear}
            dataType={dataType}
            handleOnKeydown={handleOnKeydown}
          />
        </div>
      )}
    </div>
  );
}
