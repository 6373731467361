import "./index.css";

import { PV_DISCLOSURE_ITEM } from "@/types/PresenterModel/PV_DISCLOSURE_ITEM";

export default function PrintContentTitle({
  selectedItem
}: {
  selectedItem: PV_DISCLOSURE_ITEM;
}) {
  return (
    <div id="contentTitleBox">
      <div className="label">
        <span>제&nbsp;&nbsp;&nbsp;목&nbsp;:&nbsp;</span>
      </div>
      <div className="content">
        <span>&nbsp;{selectedItem.summaryShort}</span>
      </div>
    </div>
  );
}
