import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";

const CATEGORY_JAPAN_MASTER = [
  {
    value: "100",
    kor: "발행등록추가서류",
    org: "100"
  },
  {
    value: "110",
    kor: "발행등록취하신고서",
    org: "110"
  },
  {
    value: "120",
    kor: "유가증권보고서",
    org: "120"
  },
  {
    value: "130",
    kor: "유가증권보고서",
    org: "130"
  },
  {
    value: "135",
    kor: "확인서",
    org: "135"
  },
  {
    value: "136",
    kor: "확인서",
    org: "136"
  },
  {
    value: "140",
    kor: "분기보고서",
    org: "140"
  },
  {
    value: "150",
    kor: "분기보고서",
    org: "150"
  },
  {
    value: "160",
    kor: "반기보고서",
    org: "160"
  },
  {
    value: "170",
    kor: "반기보고서",
    org: "170"
  },
  {
    value: "180",
    kor: "임시보고서",
    org: "180"
  },
  {
    value: "190",
    kor: "임시보고서",
    org: "190"
  },
  {
    value: "200",
    kor: "모회사상황보고서",
    org: "200"
  },
  {
    value: "210",
    kor: "모회사상황보고서",
    org: "210"
  },
  {
    value: "220",
    kor: "자사주매입보고서",
    org: "220"
  },
  {
    value: "230",
    kor: "자사주매입보고서",
    org: "230"
  },
  {
    value: "235",
    kor: "내부통제보고서",
    org: "235"
  },
  {
    value: "236",
    kor: "내부통제보고서",
    org: "236"
  },
  {
    value: "240",
    kor: "공개매수신고서",
    org: "240"
  },
  {
    value: "250",
    kor: "공개매수신고서",
    org: "250"
  },
  {
    value: "260",
    kor: "공개매수철회신고서",
    org: "260"
  },
  {
    value: "270",
    kor: "공개매수보고서",
    org: "270"
  },
  {
    value: "280",
    kor: "공개매수보고서",
    org: "280"
  },
  {
    value: "290",
    kor: "의견표명보고서",
    org: "290"
  },
  {
    value: "300",
    kor: "의견표명보고서",
    org: "300"
  },
  {
    value: "310",
    kor: "질의응답보고서",
    org: "310"
  },
  {
    value: "320",
    kor: "질의응답보고서",
    org: "320"
  },
  {
    value: "330",
    kor: "별도매입특례신청서",
    org: "330"
  },
  {
    value: "340",
    kor: "별도매입특례신청서",
    org: "340"
  },
  {
    value: "350",
    kor: "대량보유보고서",
    org: "350"
  },
  {
    value: "360",
    kor: "대량보유보고서",
    org: "360"
  },
  {
    value: "370",
    kor: "기준일신고서",
    org: "370"
  },
  {
    value: "380",
    kor: "변경신고서",
    org: "380"
  },
  {
    value: "010",
    kor: "유가증권통지서",
    org: "010"
  },
  {
    value: "020",
    kor: "유가증권통지서",
    org: "020"
  },
  {
    value: "030",
    kor: "유가증권신고서",
    org: "030"
  },
  {
    value: "040",
    kor: "유가증권신고서",
    org: "040"
  },
  {
    value: "050",
    kor: "신고취소",
    org: "050"
  },
  {
    value: "060",
    kor: "발행등록통지서",
    org: "060"
  },
  {
    value: "070",
    kor: "발행등록통지서",
    org: "070"
  },
  {
    value: "080",
    kor: "발행등록서",
    org: "080"
  },
  {
    value: "090",
    kor: "발행등록서",
    org: "090"
  }
];

export type PV_CATEGORY_MAPPING_JAPAN_KEY = keyof typeof CATEGORY_MAPPING_JAPAN;

export const CATEGORY_MAPPING_JAPAN = CATEGORY_JAPAN_MASTER.reduce(
  (acc, cur) => {
    acc[cur.value] = cur;
    return acc;
  },
  {} as {
    [key in string]: { kor: string; value: string; org: string };
  }
);
const CATEGORY_MAPPING_JAPAN_MAP = new Map<string, Array<string>>();
Object.keys(CATEGORY_MAPPING_JAPAN).forEach((v) => {
  const key = v as PV_CATEGORY_MAPPING_JAPAN_KEY;
  const value = CATEGORY_MAPPING_JAPAN[key].kor;
  const list =
    CATEGORY_MAPPING_JAPAN_MAP.get(CATEGORY_MAPPING_JAPAN[key].kor) ?? [];
  if (list.length === 0) {
    CATEGORY_MAPPING_JAPAN_MAP.set(value, list);
  }
  list.push(v);
});

export const JAPAN_CATEGORY_OPTIONS: Array<PV_SELECT_OPTION<Array<string>>> = [
  ...CATEGORY_MAPPING_JAPAN_MAP.keys()
]
  .sort((a, b) => a.localeCompare(b))
  .map((key) => {
    const targetValue = CATEGORY_MAPPING_JAPAN_MAP.get(key) ?? [];

    return {
      shortLabel: key,
      longLabel: targetValue.join(", "),
      resultLabel: key,
      value: targetValue
    };
  });
