import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";

import { HONGKONG_CATEGORY_OPTIONS } from "@/shared/Disclosure/constants/HONGKONG_CATEGORY_OPTIONS";
import { JAPAN_CATEGORY_OPTIONS } from "@/shared/Disclosure/constants/JAPAN_CATEGORY_OPTIONS";
import { SHANGHAI_CATEGORY_OPTIONS } from "@/shared/Disclosure/constants/SHANGHAI_CATEGORY_OPTIONS";
import { SHENZHEN_CATEGORY_OPTIONS } from "@/shared/Disclosure/constants/SHENZHEN_CATEGORY_OPTIONS";
import { USA_CATEGORY_OPTIONS } from "@/shared/Disclosure/constants/USA_CATEGORY_OPTIONS";
import { VIETNAM_CATEGORY_OPTIONS } from "@/shared/Disclosure/constants/VIETNAM_CATEGORY_OPTIONS";

export const CATEGORY_MAPPING_UNKNOWN = "기타공시";

export const CATEGORY_OPTIONS: {
  [key in GQL_EXCHANGE_TYPE]: Array<PV_SELECT_OPTION<string[]>>;
} = {
  [GQL_EXCHANGE_TYPE.USA]: USA_CATEGORY_OPTIONS,
  [GQL_EXCHANGE_TYPE.SHANGHAI]: SHANGHAI_CATEGORY_OPTIONS,
  [GQL_EXCHANGE_TYPE.HONGKONG]: HONGKONG_CATEGORY_OPTIONS,
  [GQL_EXCHANGE_TYPE.SHENZHEN]: SHENZHEN_CATEGORY_OPTIONS,
  [GQL_EXCHANGE_TYPE.JAPAN]: JAPAN_CATEGORY_OPTIONS,
  [GQL_EXCHANGE_TYPE.HOCHIMINH]: [],
  [GQL_EXCHANGE_TYPE.HANOI]: VIETNAM_CATEGORY_OPTIONS
};
