import { GQL_ENUM_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { ORG_DOC_WHITE_LIST_DOMAIN_REGX_MAP } from "@/utils/regex";

export function parseDocUrl(
  exchangeType: GQL_ENUM_EXCHANGE_TYPE,
  url?: string
) {
  if (!url) return "";

  const regx = ORG_DOC_WHITE_LIST_DOMAIN_REGX_MAP[exchangeType];

  if (!regx) return "";

  if (regx.test(url)) {
    return url;
  }

  return "";
}
