import { PV_DISCLOSURE_SECURITY_CATALOG } from "@/types/PresenterModel/PV_DISCLOSURE_SECURITY_CATALOG";
import { API_REQ_SECURITY_CATALOG_LIST } from "@/types/apiModel/Disclosure/API_REQ_SECURITY_CATALOG_LIST";
import createGraphqlClient from "@/utils/createGraphqlClient";
import { gql } from "graphql-request";
import { API_RES_SECURITY_NAME } from "../../types/apiModel/Disclosure/API_RES_SECURITY_NAME";
import { isSecurityCatalogListItem } from "./isSecurityCatalogListItem";

const query = (p: API_REQ_SECURITY_CATALOG_LIST) => gql`
  query overseasMaster {
    getSecurityName(filter: { 
      exchangeType: ${p.exchangeType}
      securityCode: "${p.securityCode}"
      searchType: ${p.searchType ? "MATCH" : "PARTIAL"}
    
    }) {
      securityCode
      securityKorName
    }
  }
`;

function parser(
  data: API_RES_SECURITY_NAME
): Array<PV_DISCLOSURE_SECURITY_CATALOG> {
  const list = (
    data?.getSecurityName || []
  ).filter<PV_DISCLOSURE_SECURITY_CATALOG>(isSecurityCatalogListItem);

  return list;
}

export async function fetchSecurityCatalog(p: API_REQ_SECURITY_CATALOG_LIST) {
  const client = createGraphqlClient();
  return parser(await client.request(query(p)));
}
