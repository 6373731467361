import CategoryCode from "@/components/SearchCondition/CategoryCode";
import Controller from "@/components/SearchCondition/Controller";
import DateFromTo from "@/components/SearchCondition/DateFromTo";
import Nation from "@/components/SearchCondition/Nation";
import SecurityCode from "@/components/SearchCondition/SecurityCode";
import SummaryTiny from "@/components/SearchCondition/SummaryTiny";
import QraftLogo from "@/components/atomic/atoms/SimplePart/Logo/QraftLogo";
import "./index.css";

export default function SearchCondition() {
  return (
    <div id="searchCondition">
      <div className="condition-row">
        <div className="condition">
          <Nation />
        </div>
        <div className="condition">
          <SecurityCode />
        </div>
        <div className="condition">
          <div>
            <QraftLogo />
          </div>
        </div>
      </div>
      <div className="condition-row">
        <div className="condition">
          <CategoryCode />
        </div>
        <div className="condition">
          <div className="sub-1">
            <SummaryTiny />
          </div>
          <div className="sub-2">
            <DateFromTo />
          </div>
        </div>
        <div className="condition">
          <Controller />
        </div>
      </div>
    </div>
  );
}
