import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";

export const VIETNAM_CATEGORY_MASTER = [
  {
    value: "CK_KHONGKYQUY",
    org: "List-ineligible for Margin",
    kor: "마진거래불가종목"
  },
  {
    value: "SURVEILLAN",
    org: "Market Surveillance",
    kor: "시장감시결과"
  },
  {
    value: "DELIST",
    org: "Delisting/Move Listing",
    kor: "상장폐지"
  },
  {
    value: "NEW_LIST",
    org: "New Listing",
    kor: "신규상장"
  },
  {
    value: "ADD_LIST",
    org: "Additional Listing",
    kor: "추가상장"
  },
  {
    value: "CH_RULE",
    org: "Change in Charter",
    kor: "내부규정개정"
  },
  {
    value: "TRADE_NEW",
    org: "Trading",
    kor: "주요주주거래"
  },
  {
    value: "TRADE_FUND",
    org: "Treasury Stock Trading",
    kor: "자사주거래"
  },
  {
    value: "TEND_OFFER",
    org: "Public Offering/Merger & Acquisition",
    kor: "IPO/M&A"
  },
  {
    value: "CORPORATE_NEWS",
    org: "Corporate Action News",
    kor: "기업결정사항"
  },
  {
    value: "GEN_MEET",
    org: "General Meeting of Shareholder",
    kor: "주주총회"
  },
  {
    value: "RESOLTION",
    org: "Board Resolution",
    kor: "이사회결의"
  },
  {
    value: "CH_LICENSE",
    org: "Change in Business Certificate",
    kor: "사업변경"
  },
  {
    value: "CH_MEMBER",
    org: "Change of Personnel",
    kor: "주요인사변경"
  },
  {
    value: "FIN_REPORT",
    org: "Financial Statement, Explanation of Financial Statement",
    kor: "재무제표"
  },
  {
    value: "GOV_REPORT",
    org: "Corporate Governance",
    kor: "기업지배구조보고서"
  },
  {
    value: "OTHER_NEW",
    org: "Others",
    kor: "기타"
  }
];

export const VIETNAM_CATEGORY_OPTIONS: Array<PV_SELECT_OPTION<Array<string>>> =
  VIETNAM_CATEGORY_MASTER.map((v) => ({
    shortLabel: v.kor,
    resultLabel: v.kor,
    longLabel: v.org,
    value: [v.value]
  }));

export const CATEGORY_MAPPING_VIETNAM = VIETNAM_CATEGORY_MASTER.reduce(
  (acc, cur) => {
    if (cur.value) acc[cur.value] = { kor: cur.kor, org: cur.org };
    return acc;
  },
  {} as {
    [key in string]: { kor: string; org: string };
  }
);
