import { ENUM_TIMEZONE } from "@/constants/ENUM_TIMEZONE";
import "./index.css";

import { NATIONAL_OPTION_LIST } from "@/constants/NATIONAL_OPTION_LIST";
import { CATEGORY_MAPPING_UNKNOWN } from "@/shared/Disclosure/constants/DisclosureCategoryNameConstants";
import { GQL_ENUM_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_DISCLOSURE_ITEM } from "@/types/PresenterModel/PV_DISCLOSURE_ITEM";
import { padCategoryCode } from "@/utils/Fomatter";
import { ErrorBoundary } from "@sentry/react";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { ReactNode } from "react";

const printExchangeMapping = NATIONAL_OPTION_LIST.reduce((acc, cur) => {
  if (cur.value) {
    acc[cur.value] = cur.shortLabel;
  }
  return acc;
}, {} as { [key in GQL_ENUM_EXCHANGE_TYPE]: string });

function Cell({ children, col }: { children?: ReactNode; col?: number }) {
  return (
    <td colSpan={col}>
      <span className="cell">{children}</span>
    </td>
  );
}

function OriginalTime({ selectedItem }: { selectedItem: PV_DISCLOSURE_ITEM }) {
  return (
    <>
      {` (현지기준 
    ${formatInTimeZone(
      selectedItem.dataDate,
      ENUM_TIMEZONE[selectedItem.exchangeType],
      "yyyy/MM/dd HH:mm:ss"
    )})`}
    </>
  );
}

export default function PrintSecurityInfo({
  selectedItem
}: {
  selectedItem: PV_DISCLOSURE_ITEM;
}) {
  return (
    <table id="securityInfoTable">
      <colgroup>
        <col width="10%" />
        <col width="30%" />
        <col width="10%" />
        <col width="30%" />
      </colgroup>
      <tbody>
        <tr>
          <Cell>종목명</Cell>
          <Cell col={3}>
            {selectedItem.securityName || selectedItem.orgSecurityName}
          </Cell>
        </tr>
        <tr>
          <Cell>종목코드</Cell>
          <Cell>
            <b>
              {selectedItem.securityCode && selectedItem.securityCode.length > 0
                ? selectedItem.securityCode.join(", ")
                : ""}
            </b>
          </Cell>
          <Cell>공시유형</Cell>
          <Cell>
            {selectedItem.categoryCode && (
              <b>
                {padCategoryCode(selectedItem.categoryCode)}{" "}
                {selectedItem.categoryName === CATEGORY_MAPPING_UNKNOWN
                  ? ""
                  : `(${selectedItem.categoryName})`}
              </b>
            )}
          </Cell>
        </tr>
        <tr>
          <Cell>국가</Cell>
          <Cell>{printExchangeMapping[selectedItem.exchangeType]}</Cell>
          <Cell>공시일</Cell>
          <Cell>
            {format(new Date(selectedItem.dataDate), "yyyy/MM/dd HH:mm:ss")}
            <ErrorBoundary>
              <br />
              <OriginalTime selectedItem={selectedItem} />
            </ErrorBoundary>

            <br />
          </Cell>
        </tr>
        {/* <tr>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
        </tr> */}
      </tbody>
    </table>
  );
}
