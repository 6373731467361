import SelectList from "@/components/atomic/atoms/SelectList";
import { useSelectListIndexStore } from "@/components/atomic/atoms/SelectList/SelectListIndexStore";
import DropDownBtn from "@/components/atomic/atoms/SimplePart/DropDownBtn";
import {
  DEF_CLEAR_SELECT_ITEM,
  DEF_NOT_EXIST_ITEM
} from "@/constants/SELECT_ITEM";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions
} from "@floating-ui/react";
import _ from "lodash";
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useRef,
  useState
} from "react";
import "./index.css";

const keyboardEventConsumer = _.throttle((fn: () => void) => {
  fn();
}, 30);

const focusLooseEventConsumer = _.debounce((fn: () => void) => {
  fn();
}, 200);

type Props<T> = {
  inputKeyword: string;
  onInputKeywordChange: (v: string) => void;
  onSelect: (v: PV_SELECT_OPTION<T> | null) => void;
  list: Array<PV_SELECT_OPTION<T>>;
  className?: string;
  needClear: boolean;
  dataType: string;
  selectorFontClass?: string;
  clearText: string;
  needDelete?: boolean;
  onDelete?: (v: PV_SELECT_OPTION<T> | null) => void;
  disabled?: boolean;
};

export default function SearchSelect<T = string>({
  className,
  inputKeyword,
  onInputKeywordChange,
  onSelect,
  list,
  needClear,
  dataType,
  selectorFontClass,
  clearText,
  needDelete,
  onDelete,
  disabled
}: Props<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const actions = useSelectListIndexStore((state) => state.actions);

  const { y, refs, strategy, context } = useFloating<HTMLDivElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(), flip(), shift()],
    whileElementsMounted: autoUpdate
  });
  const { getReferenceProps } = useInteractions([useDismiss(context)]);

  const toggleOpen = useCallback(() => {
    if (!isOpen) {
      inputRef.current?.focus();
    } else {
      setIsOpen(false);
    }
  }, [isOpen, !!disabled]);

  const handleOnInputClick = () => {
    if (!disabled) {
      isOpen || setIsOpen(true);
    }
  };

  const handleOnSelect = (v: PV_SELECT_OPTION<T> | null) => {
    onSelect(v);
    setIsOpen(false);
  };

  const handleOnKeywordChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onInputKeywordChange(e.target.value ?? "");
  };

  const handleOnKeydown: KeyboardEventHandler<
    HTMLInputElement | HTMLLIElement
  > = (e) =>
    keyboardEventConsumer(() => {
      if (e.key !== "esc" && !isOpen) {
        if (!disabled) {
          setIsOpen(true);
        }
      }

      if (e.key === "ArrowUp") {
        e.preventDefault();
        actions.subtractIdx();

        return;
      }

      if (e.key === "ArrowDown") {
        e.preventDefault();
        actions.addIdx();

        return;
      }

      if (e.key === "Enter") {
        const idx = actions.getTargetIdx();

        const targetIdx = needClear ? idx - 1 : idx;
        if (targetIdx === -1) {
          onSelect(isOpen ? DEF_CLEAR_SELECT_ITEM : null);
        } else {
          if (list[targetIdx]) {
            onSelect(list[targetIdx]);
          } else {
            onSelect(DEF_NOT_EXIST_ITEM);
          }
        }
        setIsOpen(false);
        return;
      }
    });

  const handleOnFocusLoose = useCallback(() => {
    focusLooseEventConsumer(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    });
  }, [inputRef.current]);

  return (
    <div
      className={[
        "atomic atoms search-select bordered",
        className,
        disabled ? "disabled" : ""
      ].join(" ")}
      ref={refs.setReference}
      {...getReferenceProps()}
      onScrollCapture={handleOnFocusLoose}
    >
      <input
        ref={inputRef}
        value={inputKeyword}
        onChange={handleOnKeywordChange}
        onClick={handleOnInputClick}
        onFocus={handleOnInputClick}
        onKeyDown={handleOnKeydown}
        className={selectorFontClass}
        disabled={disabled}
      />
      <DropDownBtn onClick={toggleOpen} />
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y - 1,
            left: refs.domReference.current?.offsetLeft ?? 0 + 6,
            zIndex: "10"
          }}
        >
          <SelectList<T>
            handleOnKeydown={handleOnKeydown}
            onDelete={onDelete}
            needDelete={needDelete}
            onSelect={handleOnSelect}
            list={list}
            clearText={clearText}
            needClear={needClear}
            dataType={dataType}
          />
        </div>
      )}
    </div>
  );
}
