import "./index.css";

export default function TableHeader() {
  return (
    <div id="tableHeader">
      <div className="th cell bg-title">종목코드</div>
      <div className="th cell bg-title">종목명</div>
      <div className="th cell bg-title">공시일</div>
      <div className="th cell bg-title">공시시간</div>
      <div className="th cell bg-title">공시유형</div>
      <div className="th cell bg-title">제목</div>
      <div className="th cell bg-title">원문</div>
    </div>
  );
}
