import {
  SYS_ZUSTAND_GET,
  SYS_ZUSTAND_SET
} from "@/shared/common/types/zustand/common";
import { create } from "zustand";

const _changeDebounceTime = 200;
let timerId: NodeJS.Timeout | null;
function _changeTimer(set: SYS_ZUSTAND_SET<STORE>) {
  set({ isChanging: true });
  timerId && clearTimeout(timerId);
  timerId = setTimeout(() => {
    set({ isChanging: false });
  }, _changeDebounceTime);
}
type STORE = FIELD & { actions: INTERFACE };

type FIELD = {
  length: number;
  idx: number;
  direction: string;
  isChanging: boolean;
};

const initField = (): FIELD => {
  return {
    length: 0,
    idx: 0,
    direction: "",
    isChanging: false
  };
};

type INTERFACE = {
  init: (length: number) => void;
  addIdx: () => void;
  subtractIdx: () => void;
  setIdx: (idx: number) => void;
  setIdxWhenNotChanging: (idx: number) => void;
  getTargetIdx: () => number;
  reset: () => void;
};

function addIdx(set: SYS_ZUSTAND_SET<STORE>, get: SYS_ZUSTAND_GET<STORE>) {
  _changeTimer(set);
  const bs = get();
  if (bs.idx < bs.length - 1) {
    set({ idx: bs.idx + 1, direction: "down" });
  } else {
    set({ idx: 0, direction: "up" });
  }
}

function subtractIdx(set: SYS_ZUSTAND_SET<STORE>, get: SYS_ZUSTAND_GET<STORE>) {
  _changeTimer(set);
  const bs = get();
  if (bs.idx > 0) {
    set({ idx: bs.idx - 1, direction: "up" });
  } else {
    set({ idx: bs.length - 1, direction: "down" });
  }
}

function setIdx(set: SYS_ZUSTAND_SET<STORE>, idx: number) {
  _changeTimer(set);
  set({ idx, direction: "" });
}

function setIdxWhenNotChanging(
  set: SYS_ZUSTAND_SET<STORE>,
  get: SYS_ZUSTAND_GET<STORE>,
  idx: number
) {
  if (!get().isChanging) {
    set({ idx, direction: "" });
  }
}

const baseInfoInterface = (
  set: SYS_ZUSTAND_SET<STORE>,
  get: SYS_ZUSTAND_GET<STORE>
): INTERFACE => {
  return {
    init: (length: number) =>
      set({ length, idx: 0, direction: "up", isChanging: false }),
    addIdx: () => addIdx(set, get),
    subtractIdx: () => subtractIdx(set, get),
    setIdx: (idx: number) => setIdx(set, idx),
    setIdxWhenNotChanging: (idx: number) =>
      setIdxWhenNotChanging(set, get, idx),
    getTargetIdx: () => get().idx,
    reset: () => set(initField())
  };
};

export const useSelectListIndexStore = create<STORE>((set, get) => ({
  ...initField(),
  actions: {
    ...baseInfoInterface(set, get)
  }
}));
