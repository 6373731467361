import fetchSvrDisclosure from "@/api/fetchDisclosureList";
import { API_REQ_DISCLOSURE_LIST } from "@/types/apiModel/Disclosure/API_REQ_DISCLOSURE_LIST";
import { useInfiniteQuery } from "@tanstack/react-query";

const checkEnable = (
  p: Partial<API_REQ_DISCLOSURE_LIST>
): p is API_REQ_DISCLOSURE_LIST => {
  return !!p.exchangeType && !!p.endDate && !!p.startDate;
};

const useRQDisclosureList = (params: Partial<API_REQ_DISCLOSURE_LIST>) => {
  const enabled = checkEnable(params);

  return useInfiniteQuery(
    ["useRQDisclousreList", ...Object.values(params)],
    ({ pageParam = 0 }) =>
      enabled ? fetchSvrDisclosure({ ...params, page: pageParam + 1 }) : null,
    {
      enabled,
      networkMode: "always",
      staleTime: 5 * 60 * 1000,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return null;

        if ((lastPage.list.length ?? 0) !== 0) return (pages || []).length;
        return null;
      },
      refetchOnWindowFocus: false
    }
  );
};

export default useRQDisclosureList;
