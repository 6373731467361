import CalendarBtn from "@/components/atomic/atoms/SimplePart/CalendarBtn";
import { format } from "date-fns";
import { ko } from "date-fns/esm/locale";
import React, {
  MouseEventHandler,
  memo,
  useEffect,
  useRef,
  useState
} from "react";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const CalendarButton = memo(function CalendarButton() {
  return <CalendarBtn />;
});

function TodayButton({ onClick }: { onClick?: () => void }) {
  return (
    <button className="react-datepicker__today-button" onClick={onClick}>
      <b>오늘: {format(new Date(), "yyyy년도 M월 d일")}</b>
    </button>
  );
}

const regxDate = /^[0-9]{4}(\/)[0-9]{2}(\/)[0-9]{2}$/;
const foramtRegxYear = /([0-9]{4})년/;
const foramtRegxMonth = /([0-9]{1,2})월/;
const foramtRegxDay = /([0-9]{1,2})일/;

export default memo(function Calendar({
  date,
  filterDate,
  onChange,
  pos
}: {
  date: string;
  filterDate?: (date: Date | string) => boolean;
  onChange: (date: Date | string) => void;
  pos: string;
}) {
  const [inputDate, setInputDate] = useState<null | Date>(null);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (!regxDate.test(date)) {
      setInputDate(null);
    }
    const newDate = new Date(date);
    if (!isNaN(newDate.getTime())) {
      setInputDate(newDate);
    }
  }, [date]);

  const ref = useRef<ReactDatePicker>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const onBtnClick: MouseEventHandler<HTMLDivElement> = () => {
    ref.current?.setOpen(true);
  };

  const handleOnFormat = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (typeof e.target.value === "undefined") {
      const rawDate = e.target.getAttribute("aria-label") ?? "";
      const newDate = [
        rawDate.match(foramtRegxYear)?.[1].padStart(4, "0"),
        rawDate.match(foramtRegxMonth)?.[1].padStart(2, "0"),
        rawDate.match(foramtRegxDay)?.[1].padStart(2, "0")
      ].join("/");

      if (newDate == "//") {
        const today = new Date();
        if (!filterDate || filterDate(today))
          onChange(format(today, "yyyy/MM/dd"));
      } else {
        onChange(newDate);
      }
      return;
    }
    const numericInput = e.target.value.replace(/[^\d]/g, "");
    const year = numericInput.slice(0, 4);

    const month = numericInput.slice(4, 6);
    const day = numericInput.slice(6, 8);

    const ymdArr = [year, month, day];
    const formattedDate = ymdArr.filter(Boolean).join("/");

    const checkDate = Date.parse(formattedDate);

    if (isNaN(checkDate) || !regxDate.test(formattedDate)) {
      setValue(formattedDate);
    } else {
      if (formattedDate !== date) {
        const newDate = format(checkDate, "yyyy/MM/dd");
        if (!filterDate || filterDate(newDate)) {
          onChange(newDate);
        } else {
          setValue(formattedDate);
        }
      } else {
        setValue(formattedDate);
      }
    }
  };

  useEffect(() => {
    setValue(date ? date.replace(/-/g, "/") : "");
  }, [date]);

  return (
    <div className="atomic molecules calendar" ref={containerRef}>
      <DatePicker
        ref={ref}
        className="calendar-box-wrap bordered"
        onCalendarOpen={() => {
          const pop = document.getElementsByClassName(
            "react-datepicker-popper"
          );

          if (pop?.[0] && containerRef?.current) {
            const el = pop[0];
            el.setAttribute("style", `top:${0}px;left:${0}px;`);
          }
        }}
        locale={ko}
        dateFormat="yyyy/MM/dd"
        dateFormatCalendar="yyyy년도 M월"
        selected={inputDate}
        onChange={() => { }}
        onChangeRaw={handleOnFormat}
        portalId={pos}
        filterDate={filterDate}
        value={value}
        strictParsing={true}
        onBlur={() => {
          setValue(date);
        }}
      >
        <TodayButton
          onClick={() => {
            const today = new Date();

            if (!filterDate || filterDate(today)) {
              ref.current?.setOpen(false);
              onChange(today);
            } else {
              ref.current?.setState((prev) => {
                return {
                  ...prev,
                  preSelection: today
                };
              });
            }
          }}
        />
      </DatePicker>
      <div className="calendar-icon-box" onClick={onBtnClick}>
        <CalendarButton />
      </div>
    </div>
  );
});
