import MainPage from "@/pages/MainPage";
import RouteGuard from "@/pages/RouteGuard";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* ie에서 react-dom-router가 통하지 않아 root를 list로 같이 사용함 */}
        <Route path="/" element={<MainPage />} />
        <Route path="/list" element={<MainPage />} />
        <Route path="*" element={<RouteGuard />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
