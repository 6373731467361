import TableBody from "@/components/DisclosureList/TableBody";
import TableHeader from "@/components/DisclosureList/TableHeader";
import "./index.css";

export default function DisclosureList() {
  return (
    <div className="content-box main-container-border">
      <div id="disclosureList">
        <TableHeader />
        <TableBody />
      </div>
    </div>
  );
}
