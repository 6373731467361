import ComplextForm from "@/components/DisclosureDetail/FormDetail/FormCommon/ComplextForm";
import FlatForm from "@/components/DisclosureDetail/FormDetail/FormCommon/FlatForm";
import { FORM_10_DETAIL_SETTING } from "@/shared/Disclosure/constants/DisclosureViewCodeConstants";
import { PV_DISCLOSURE_10_FORM_DETAIL } from "@/shared/Disclosure/types/PresenterModel/PV_DISCLOSURE_10_FORM_DETAIL";
import { isFieldOf } from "@/shared/common/utils/object/objectUtils";
import { ReactNode } from "react";

function Wrapper({ children }: { children: ReactNode }) {
  return <>{children}</>;
}

export default function Form10({
  data
}: {
  data: PV_DISCLOSURE_10_FORM_DETAIL;
}) {
  return (
    <>
      {FORM_10_DETAIL_SETTING.filter((v) => isFieldOf(data, v.key)).map((v) => (
        <Wrapper key={v.key}>
          {v.type === 1 && (v.key === "business" || v.key === "repurchase") ? (
            <FlatForm title={v.title} text={data[v.key] ?? ""} />
          ) : (
            (v.type === 2 || v.type === 3) &&
            v.key !== "business" &&
            v.key !== "repurchase" && (
              <ComplextForm
                title={v.title}
                needOrder={v.type === 3}
                list={data[v.key] ?? []}
              />
            )
          )}
        </Wrapper>
      ))}
    </>
  );
}
