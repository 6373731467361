import { fetchSecurityCatalog } from "@/api/fetchSecurityCatalog";
import { PV_DISCLOSURE_SECURITY_CATALOG } from "@/types/PresenterModel/PV_DISCLOSURE_SECURITY_CATALOG";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";
import { API_REQ_SECURITY_CATALOG_LIST } from "@/types/apiModel/Disclosure/API_REQ_SECURITY_CATALOG_LIST";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";

const checkEnable = (
  p: Partial<API_REQ_SECURITY_CATALOG_LIST>
): p is API_REQ_SECURITY_CATALOG_LIST => {
  return !!p.exchangeType && !!p.securityCode && p.securityCode.length <= 8;
};

const useRQSecurityCatalog = (
  params: Partial<API_REQ_SECURITY_CATALOG_LIST>
) => {
  const enabled = checkEnable(params);

  return useQuery(
    ["useRQSecurityCatalog", ...Object.values(params)],
    () => (enabled ? fetchSecurityCatalog(params) : []),
    {
      // enabled,
      select: (res): Array<PV_SELECT_OPTION<string>> => {
        const symbolMatchList: Array<PV_DISCLOSURE_SECURITY_CATALOG> = [];
        const symbolUnmatchList: Array<PV_DISCLOSURE_SECURITY_CATALOG> = [];

        res.sort((a, b) => a.securityCode.localeCompare(b.securityCode));

        _.sortedUniqBy(res, (v) => v.securityCode).forEach((v) =>
          v.securityCode.indexOf(params.securityCode ?? "") === 0
            ? symbolMatchList.push(v)
            : symbolUnmatchList.push(v)
        );

        return [...symbolMatchList, ...symbolUnmatchList].map((v) => ({
          shortLabel: v.securityCode,
          longLabel: v.securityKorName,
          value: v.securityCode
        }));
      },
      networkMode: "always",
      staleTime: 60 * 1000,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );
};

export default useRQSecurityCatalog;
