import {
  PV_SELECT_OPTION,
  SELECT_OPTION_TYPE
} from "@/types/PresenterModel/PV_SELECT_OPTION";

export const DEF_CLEAR_SELECT_ITEM: PV_SELECT_OPTION<never> = {
  shortLabel: "",
  longLabel: "",
  type: SELECT_OPTION_TYPE.CLEAR,
  value: null
};

export const DEF_NOT_EXIST_ITEM: PV_SELECT_OPTION<never> = {
  shortLabel: "",
  longLabel: "",
  type: SELECT_OPTION_TYPE.NOT_EXISTS,
  value: null
};
