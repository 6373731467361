import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RouteGuard = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [pathname]);

  return <></>;
};

export default RouteGuard;
