import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";

export const NATIONAL_OPTION_LIST: Array<PV_SELECT_OPTION<GQL_EXCHANGE_TYPE>> =
  [
    {
      shortLabel: "미국",
      longLabel: "미국",
      value: GQL_EXCHANGE_TYPE.USA
    },
    {
      shortLabel: "홍콩",
      longLabel: "홍콩",
      value: GQL_EXCHANGE_TYPE.HONGKONG
    },
    {
      shortLabel: "중국(상해)",
      longLabel: "중국(상해)",
      value: GQL_EXCHANGE_TYPE.SHANGHAI
    },
    {
      shortLabel: "중국(심천)",
      longLabel: "중국(심천)",
      value: GQL_EXCHANGE_TYPE.SHENZHEN
    },
    {
      shortLabel: "일본",
      longLabel: "일본",
      value: GQL_EXCHANGE_TYPE.JAPAN
    },
    {
      shortLabel: "베트남(호치민)",
      longLabel: "베트남(호치민)",
      value: GQL_EXCHANGE_TYPE.HOCHIMINH
    },
    {
      shortLabel: "베트남(하노이)",
      longLabel: "베트남(하노이)",
      value: GQL_EXCHANGE_TYPE.HANOI
    }
  ];
