import { PV_DISCLOSURE_DEF14_FORM_ITEM } from "@/shared/Disclosure/types/PresenterModel/PV_DISCLOSURE_DEF14_FORM_ITEM";

function SubBody({ title, text }: { title: string; text: string }) {
  return (
    <div className="content-part  page-break">
      <div>
        <span>{title}</span>
      </div>
      <div className="content-part">
        <div className="body">
          <span>{text}</span>
        </div>
      </div>
    </div>
  );
}

type Props = {
  title: string;
  list: Array<PV_DISCLOSURE_DEF14_FORM_ITEM>;
  needOrder?: boolean;
};

export default function ComplextForm({
  title,
  list,
  needOrder = false
}: Props) {
  return (
    <div className="content-part page-break">
      <div className="title">
        <span>{title}</span>
      </div>
      <div className="body">
        {list.map((v) => (
          <SubBody
            key={(needOrder ? v.no + ". " : "") + v.title}
            title={(needOrder ? v.no + ". " : "") + v.title}
            text={v.text}
          />
        ))}
      </div>
    </div>
  );
}
