import FlatForm from "@/components/DisclosureDetail/FormDetail/FormCommon/FlatForm";
import { PV_DISCLOSURE_DEF14_FORM_ITEM } from "@/shared/Disclosure/types/PresenterModel/PV_DISCLOSURE_DEF14_FORM_ITEM";

export default function Form14({
  data
}: {
  data: Array<PV_DISCLOSURE_DEF14_FORM_ITEM>;
}) {
  return (
    <>
      {data.map((v) => (
        <FlatForm key={v.no} no={v.no} text={v.text} />
      ))}
    </>
  );
}
