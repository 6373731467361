import ArticleDetail from "@/components/DisclosureDetail/ArticleDetail";
import FormDetail from "@/components/DisclosureDetail/FormDetail";
import "./index.css";

export default function DisclosureDetail() {
  return (
    <>
      <ArticleDetail />
      <FormDetail />
    </>
  );
}
