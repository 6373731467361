import { useUiConditionStore } from "@/components/_store/zustand/useUiConditionStore";
import DateControl from "@/components/atomic/organisms/DateControl";
import { format } from "date-fns";

export default function DateFromTo() {
  const startDate = useUiConditionStore((state) => state.startDate);
  const setStartDate = useUiConditionStore(
    (state) => (v: Date | string) =>
      typeof v === "string"
        ? state.actions.setStringInput("startDate", v)
        : state.actions.setStringInput("startDate", format(v, "yyyy/MM/dd")),
    () => true
  );
  const endDate = useUiConditionStore((state) => state.endDate);
  const setEndDate = useUiConditionStore(
    (state) => (v: Date | string) =>
      typeof v === "string"
        ? state.actions.setStringInput("endDate", v)
        : state.actions.setStringInput("endDate", format(v, "yyyy/MM/dd")),
    () => true
  );

  return (
    <DateControl
      labelText="조회기간"
      startDate={startDate}
      endDate={endDate}
      onSelectStartDate={setStartDate}
      onSelectEndDate={setEndDate}
    />
  );
}
