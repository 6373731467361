import "../common/ControlBtn/index.css";
import "./index.css";

import { ReactComponent as CloseBtnSvg } from "./closeBtn.svg";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export default function CloseBtn(props: Props) {
  return (
    <button
      type="button"
      {...props}
      className={[
        "atomic atoms simple-part close-btn bg-transparent",
        props.className
      ].join(" ")}
    >
      <CloseBtnSvg />
    </button>
  );
}
