import { convertCategoryCodeToName } from "@/api/fetchDisclosureList/common/convertCategoryCodeToName";
import { parseDocUrl } from "@/api/fetchDisclosureList/common/parseDocUrl";
import { parseTopic } from "@/api/fetchDisclosureList/common/parseTopic";
import { GQL_ENUM_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_DISCLOSURE_ITEM } from "@/types/PresenterModel/PV_DISCLOSURE_ITEM";
import { API_RES_USA_LIST_ITEM } from "../../../types/apiModel/Disclosure/API_RES_DISCLOSURE_USA";
import { parseUSAForm10 } from "./parseUSAForm10";
import { parseUSAForm14 } from "./parseUSAForm14";

export function parserUSAListItem(
  v: API_RES_USA_LIST_ITEM,
  exchangeType: GQL_ENUM_EXCHANGE_TYPE
): PV_DISCLOSURE_ITEM | null {
  if (!v.id) return null;

  return {
    id: v.id,
    dataDate: v.dataDate ?? "",
    securityCode: v.details.ticker ? v.details.ticker.filter((v) => v) : [],
    securityName: v.korName ?? "",
    orgSecurityName: v.details.secNameList?.[0] ?? "",
    categoryCode: v.details.form,
    categoryName: convertCategoryCodeToName(exchangeType, v.details.form),
    tags: parseTopic(v.analysisDetails.topicKor),
    summaryShort:
      (v.details.form && v.details.form.indexOf("/A") > 0
        ? "(정정공시) "
        : "") + v.analysisDetails.summarizeTinyKor ?? "",
    summaryLong: v.analysisDetails.summarizeLongKor ?? "",
    fileUrl: parseDocUrl(exchangeType, v.details.mainUrl),
    exchangeType,
    formData: v.id
      ? {
          form10: parseUSAForm10(v.id, v.details.analysisForm10s),
          form14: parseUSAForm14(v.id, v.details.analysisDEF14A)
        }
      : null
  };
}
