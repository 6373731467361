import "./index.css";

import { InitialLoader } from "./InitialLoader";
import { ResetButton } from "./ResetButton";
import { SearchButton } from "./SearchButton";

export default function Controller() {
  return (
    <div id="controller">
      <InitialLoader />
      <ResetButton />
      <SearchButton />
    </div>
  );
}
