import { uiCondToParamCond } from "@/components/SearchCondition/Controller/uiCondToParamCond";
import { useInitialConditionStore } from "@/components/_store/zustand/useInitialConditionStore";
import { useSearchConditionStore } from "@/components/_store/zustand/useSearchConditionStore";
import { useUiConditionStore } from "@/components/_store/zustand/useUiConditionStore";
import SearchSelectControl from "@/components/atomic/organisms/SearchSelectControl";
import { SECURITY_HISTORY_SIZE } from "@/constants/SECURITY_HISTORY_SIZE";
import { useSecurityCatalog } from "@/hook/useSecurityCatalog";
import {
  PV_SELECT_OPTION,
  SELECT_OPTION_TYPE
} from "@/types/PresenterModel/PV_SELECT_OPTION";
import { getJsonData, setJsonData } from "@/utils/history";
import {
  AMP_PRESS_ENTER_SECURITY_CODE_CONDITION,
  logEvent
} from "@/utils/logEvent";
import _ from "lodash";
import { useEffect, useState } from "react";
import { makeSecurityCodeKey } from "../../../utils/makeSecurityCodeKey";

const regxKeyowrd = /(%|"|'|\\|\?)/g;
const MODE_HISTORY = "history";
const MODE_KEYWORD = "keyowrd";

const DELETE_KEYWORD = "__DELETE__";

const DELETE_ALL_ITEM: PV_SELECT_OPTION<string> = {
  shortLabel: "",
  longLabel: "전체삭제",
  resultLabel: "",
  value: DELETE_KEYWORD
};

const actionConsumer = _.throttle((fn: () => void) => {
  fn();
}, 300);

export default function SecurityCode() {
  const conditionActions = useSearchConditionStore((state) => state.actions);
  const uiCondition = useUiConditionStore((state) => state);
  const selectedItem = useUiConditionStore(
    (state) => state.securityCode.selected
  );
  const inputKeyword = useUiConditionStore(
    (state) => state.securityCode.inputKeyword
  );

  const initialParams = useInitialConditionStore(
    (state) => state.initialParams
  );

  const { data: list } = useSecurityCatalog();

  const [mode, setMode] = useState(MODE_HISTORY);

  const [historyList, setHistoryList] = useState<
    Array<PV_SELECT_OPTION<string>>
  >([]);

  useEffect(() => {
    if (uiCondition?.exchangeCode?.selected?.value) {
      const histories = getJsonData(
        makeSecurityCodeKey(uiCondition.exchangeCode.selected.value)
      );
      setHistoryList(histories ?? []);
      setMode(MODE_HISTORY);
    } else {
      setHistoryList([]);
    }
  }, [uiCondition?.exchangeCode?.selected?.value, initialParams]);

  const setInputKeyword = useUiConditionStore(
    (state) => (v: string) => {
      const keyword = v.replace(regxKeyowrd, "").slice(0, 8);
      state.actions.setInputKeyword("securityCode", keyword);
      if (keyword) {
        setMode(MODE_KEYWORD);
      } else {
        setMode(MODE_HISTORY);
      }
    },
    () => true
  );

  const selectItem = useUiConditionStore(
    (state) => (v: PV_SELECT_OPTION<string> | null) => {
      state.actions.selectItem("securityCode", v);
      setMode(MODE_HISTORY);
    },
    () => true
  );

  const selectItemWrap = (v: PV_SELECT_OPTION<string> | null) => {
    if (uiCondition?.exchangeCode?.selected?.value) {
      if (v?.value) {
        if (v?.value === DELETE_KEYWORD) {
          const newList: Array<PV_SELECT_OPTION<string>> = [];
          setJsonData(
            makeSecurityCodeKey(uiCondition.exchangeCode.selected.value),
            newList
          );
          setHistoryList(newList);
          return;
        }
        const newList = [
          v,
          ...historyList.filter((bef) => bef.value !== v.value)
        ].slice(0, SECURITY_HISTORY_SIZE);
        setJsonData(
          makeSecurityCodeKey(uiCondition.exchangeCode.selected.value),
          newList
        );
        setHistoryList(newList);
        selectItem(v);
      } else {
        setMode(MODE_HISTORY);
        selectItem(null);
      }
    }
  };

  const search = (targetItem: PV_SELECT_OPTION<string> | null) => {
    if (targetItem?.value === DELETE_KEYWORD) return;
    const params = uiCondToParamCond({
      exchangeCode: uiCondition.exchangeCode,
      securityCode: {
        inputKeyword: "",
        keyword: "",
        selected: targetItem
      },
      categoryType: uiCondition.categoryType,
      includeTinyKor: uiCondition.includeTinyKor,
      startDate: uiCondition.startDate,
      endDate: uiCondition.endDate
    });

    logEvent(AMP_PRESS_ENTER_SECURITY_CODE_CONDITION, params);

    conditionActions.setCondition(params);
  };

  const handleOnSelect = (targetItem: PV_SELECT_OPTION<string> | null) => {
    actionConsumer(() => {
      const item = !targetItem
        ? selectedItem
        : targetItem.type === SELECT_OPTION_TYPE.CLEAR ||
          targetItem.type === SELECT_OPTION_TYPE.NOT_EXISTS
        ? null
        : targetItem;
      if (uiCondition?.exchangeCode?.selected?.value) {
        setMode(MODE_HISTORY);
        selectItemWrap(item);
        search(item);
      }
    });
  };

  const handleOnDelete = (v: PV_SELECT_OPTION<string> | null) => {
    actionConsumer(() => {
      if (uiCondition?.exchangeCode?.selected?.value) {
        if (v?.value) {
          if (v?.value === DELETE_KEYWORD) {
            const newList: Array<PV_SELECT_OPTION<string>> = [];
            setJsonData(
              makeSecurityCodeKey(uiCondition.exchangeCode.selected.value),
              newList
            );
            setHistoryList(newList);
            return;
          }

          const befHistories: PV_SELECT_OPTION<string>[] = getJsonData(
            makeSecurityCodeKey(uiCondition.exchangeCode.selected.value)
          );

          if (!befHistories) return;

          const newList: Array<PV_SELECT_OPTION<string>> = [
            ...befHistories.filter((bef) => bef.value !== v.value)
          ];
          setJsonData(
            makeSecurityCodeKey(uiCondition.exchangeCode.selected.value),
            newList
          );
          setHistoryList(newList);
        }
      }
    });
  };

  return (
    <>
      <SearchSelectControl<string>
        showResult={true}
        inputKeyword={inputKeyword}
        onInputKeywordChange={setInputKeyword}
        onSelect={handleOnSelect}
        onDelete={handleOnDelete}
        selectedItem={selectedItem}
        needClear={mode === MODE_HISTORY}
        needDelete={mode === MODE_HISTORY}
        list={
          mode === MODE_KEYWORD
            ? list
            : historyList.length > 0
            ? [...historyList, DELETE_ALL_ITEM]
            : []
        }
        labelText="종목코드"
        // selectorFontClass="font-medium"
        dataType="securityCode"
      />
    </>
  );
}
