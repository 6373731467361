export default function Seperator({ style }: { style?: React.CSSProperties }) {
  return (
    <div
      style={{
        display: "inline-block",
        width: "1px",
        background: "#727981",
        margin: "0 5px",
        ...(style || {})
      }}
    ></div>
  );
}
