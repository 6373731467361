import ResultShow from "@/components/atomic/atoms/ResultShow";
import Select from "@/components/atomic/atoms/Select";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";
import "./index.css";

type Props<T> = {
  onSelect: (v: PV_SELECT_OPTION<T> | null) => void;
  list: Array<PV_SELECT_OPTION<T>>;
  selectedItem: PV_SELECT_OPTION<T> | null;
  needClear?: boolean;
  selectorFontClass?: string;
  resultFontClass?: string;
  dataType: string;
  clearText: string;
  showResult?: boolean;
  disabled?: boolean;
};

export default function SelectResult<T = string>({
  list,
  selectedItem,
  onSelect,
  needClear,
  selectorFontClass,
  resultFontClass,
  dataType,
  clearText,
  showResult,
  disabled
}: Props<T>) {
  return (
    <div className="atomic molecules select-result">
      <Select<T>
        needClear={needClear}
        className="mr-1"
        list={list}
        keyword={selectedItem?.resultLabel ?? selectedItem?.longLabel ?? ""}
        onSelect={onSelect}
        dataType={dataType}
        selectorFontClass={selectorFontClass}
        clearText={clearText}
        disabled={disabled}
      />
      {showResult && (
        <ResultShow
          value={selectedItem?.resultLabel ?? selectedItem?.longLabel ?? ""}
          resultFontClass={resultFontClass}
          clearText={clearText}
        />
      )}
    </div>
  );
}
