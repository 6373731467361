import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";

const CATEGORY_HONGKONG_MASTER = [
  {
    value: "Announcements and Notices",
    kor: "일반공시",
    org: "Announcements and Notices"
  },
  {
    value: "Circulars",
    kor: "주요안내보고서",
    org: "Circulars"
  },
  {
    value: "Listing Documents",
    kor: "보고서",
    org: "Listing Documents"
  },
  {
    value: "Financial Statements/ESG Information",
    kor: "재무/ESG",
    org: "Financial Statements/ESG Information"
  },
  {
    value: "Next Day Disclosure Returns",
    kor: "발행주식수변동",
    org: "Next Day Disclosure Returns"
  },
  {
    value: "Monthly Returns",
    kor: "월간보고서",
    org: "Monthly Returns"
  },
  {
    value: "Proxy Forms",
    kor: "대리행사",
    org: "Proxy Forms"
  },
  {
    value: "Company Information Sheet",
    kor: "회사개요",
    org: "Company Information Sheet"
  },
  {
    value: "Trading Information of Exchange Traded Funds",
    kor: "ETF정보",
    org: "Trading Information of Exchange Traded Funds"
  },
  {
    value: "Trading Information of Leveraged and Inverse Products",
    kor: "파생상품거래정보",
    org: "Trading Information of Leveraged and Inverse Products"
  },
  {
    value: "Constitutional Documents",
    kor: "정관수정",
    org: "Constitutional Documents"
  },
  {
    value: "Documents on Display",
    kor: "기타공시",
    org: "Documents on Display"
  },
  {
    value: "Regulatory Announcement & News",
    kor: "공지/뉴스",
    org: "Regulatory Announcement & News"
  },
  {
    value: "Takeovers Code - dealing disclosures",
    kor: "거래공시",
    org: "Takeovers Code - dealing disclosures"
  },
  {
    value: "Debt and Structured Products",
    kor: "부채/구조화상품",
    org: "Debt and Structured Products"
  },
  {
    value: "Documents on Display (Debt Issuance Programmes)",
    kor: "채권발행",
    org: "Documents on Display (Debt Issuance Programmes)"
  },
  {
    value: "Documents on Display (Debt Securities)",
    kor: "채무증권",
    org: "Documents on Display (Debt Securities)"
  },
  {
    value: "Documents on Display (Structured Products)",
    kor: "구조화상품",
    org: "Documents on Display (Structured Products)"
  }
];

export type PV_CATEGORY_MAPPING_HONGKONG_KEY =
  keyof typeof CATEGORY_MAPPING_HONGKONG;

export const CATEGORY_MAPPING_HONGKONG = CATEGORY_HONGKONG_MASTER.reduce(
  (acc, cur) => {
    acc[cur.value] = cur;
    return acc;
  },
  {} as {
    [key in string]: { kor: string; value: string; org: string };
  }
);
const CATEGORY_MAPPING_HONGKONG_MAP = new Map<string, Array<string>>();
Object.keys(CATEGORY_MAPPING_HONGKONG).forEach((v) => {
  const key = v as PV_CATEGORY_MAPPING_HONGKONG_KEY;
  const value = CATEGORY_MAPPING_HONGKONG[key].kor;
  const list =
    CATEGORY_MAPPING_HONGKONG_MAP.get(CATEGORY_MAPPING_HONGKONG[key].kor) ?? [];
  if (list.length === 0) {
    CATEGORY_MAPPING_HONGKONG_MAP.set(value, list);
  }
  list.push(v);
});

export const HONGKONG_CATEGORY_OPTIONS: Array<PV_SELECT_OPTION<Array<string>>> =
  [...CATEGORY_MAPPING_HONGKONG_MAP.keys()]
    .sort((a, b) => a.localeCompare(b))
    .map((key) => {
      const targetValue = CATEGORY_MAPPING_HONGKONG_MAP.get(key) ?? [];

      return {
        shortLabel: key,
        longLabel: targetValue.join(", "),
        resultLabel: key,
        value: targetValue
      };
    });
