import "./index.css";

import { CAUTION_TEXT } from "@/constants/CAUTION_TEXT";

export default function PrintTail() {
  return (
    <div id="printCautionBox">
      <div id="printCautionText">
        <span className="page-break">
          <b>※ [유의사항]</b>&nbsp;&nbsp;&nbsp;{CAUTION_TEXT}
        </span>
      </div>
    </div>
  );
}
