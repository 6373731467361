import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";

export const ENUM_TIMEZONE = {
  [GQL_EXCHANGE_TYPE.USA]: "America/New_York",
  [GQL_EXCHANGE_TYPE.HONGKONG]: "Asia/Hong_Kong",
  [GQL_EXCHANGE_TYPE.SHENZHEN]: "Asia/Shanghai",
  [GQL_EXCHANGE_TYPE.SHANGHAI]: "Asia/Shanghai",
  [GQL_EXCHANGE_TYPE.JAPAN]: "Asia/tokyo",
  [GQL_EXCHANGE_TYPE.HOCHIMINH]: "Asia/Ho_Chi_Minh",
  [GQL_EXCHANGE_TYPE.HANOI]: "Asia/Ho_Chi_Minh"
};
