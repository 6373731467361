import { FORM_10_FIELD_SETTING } from "@/shared/Disclosure/constants/DisclosureViewCodeConstants";
import { GQL_TYPE_DISCLOSURE_ANALYSIS_TO_USA_FORM_10S } from "@/shared/Disclosure/types/GQL/model/DisclosureDetails/types/form/DisclosureAnalysisToUSAForm10s";

export function parseUSAForm10(
  id: string,
  data?: GQL_TYPE_DISCLOSURE_ANALYSIS_TO_USA_FORM_10S
) {
  return data && FORM_10_FIELD_SETTING.some((v) => data[v.fieldKey])
    ? { ...data, id }
    : null;
}
