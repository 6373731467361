export type GQL_ENUM_EXCHANGE_TYPE =
  | "SHENZHEN"
  | "SHANGHAI"
  | "HONGKONG"
  | "USA"
  | "JAPAN"
  | "HOCHIMINH"
  | "HANOI";

export enum GQL_EXCHANGE_TYPE {
  USA = "USA",
  HONGKONG = "HONGKONG",
  SHANGHAI = "SHANGHAI",
  SHENZHEN = "SHENZHEN",
  JAPAN = "JAPAN",
  HOCHIMINH = "HOCHIMINH",
  HANOI = "HANOI"
}
