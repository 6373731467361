import encryptWithUnixTime from "@/utils/encryptWithUnixTime";
import { GraphQLClient } from "graphql-request";

const endPoint = import.meta.env.VITE_API_SERVER_URL;

const createGraphqlClient = () => {
  return new GraphQLClient(endPoint, {
    headers: () => {
      const header = encryptWithUnixTime();
      if (!header) return { _key: "", _t: "" };
      return {
        _key: header.encrypted,
        _t: header.unixTime.toString()
      };
    }
  });
};

export default createGraphqlClient;
