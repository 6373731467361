import { useContentFontStore } from "@/components/_store/zustand/useContentFontStore";
import { useSelectItemStore } from "@/components/_store/zustand/useSelectItemStore";
import "./index.css";

export default function ContentTextBox() {
  const selectedItem = useSelectItemStore((state) => state.item);

  const fontSize = useContentFontStore(
    (state) => state.actions.getCurrentFontSize() + "px"
  );

  return (
    <div id="contentTextBox" style={{ fontSize }}>
      {selectedItem?.selectedItem.summaryLong}
    </div>
  );
}
