import { uiCondToParamCond } from "@/components/SearchCondition/Controller/uiCondToParamCond";
import { useSearchConditionStore } from "@/components/_store/zustand/useSearchConditionStore";
import { useUiConditionStore } from "@/components/_store/zustand/useUiConditionStore";
import KeywordControl from "@/components/atomic/organisms/KeywordControl";
import {
  AMP_PRESS_ENTER_SUMMARY_TINY_CONDITION,
  logEvent
} from "@/utils/logEvent";
import _ from "lodash";

const MAX_SUMMARY_TINY_LENGTH = 50;
const INVALIDATE_CHAR_REGX = /\\/g;

const consumer = _.throttle((fn: () => void) => {
  fn();
}, 500);

export default function SummaryTiny() {
  const conditionActions = useSearchConditionStore((state) => state.actions);

  const uiCondition = useUiConditionStore((state) => state);
  const setStringInput = useUiConditionStore(
    (state) => (v: string) => {
      const newString = (v || "")
        .replace(INVALIDATE_CHAR_REGX, "")
        .substring(0, MAX_SUMMARY_TINY_LENGTH);
      state.actions.setStringInput("includeTinyKor", newString);
    },
    () => true
  );

  const onSearch = () => {
    // const befIncludeTinyKor = conditionActions.getBefSummaryTiny();

    // if ((befIncludeTinyKor || "") === uiCondition.includeTinyKor) return;
    consumer(() => {
      const params = uiCondToParamCond({
        exchangeCode: uiCondition.exchangeCode,
        securityCode: uiCondition.securityCode,
        categoryType: uiCondition.categoryType,
        includeTinyKor: uiCondition.includeTinyKor,
        startDate: uiCondition.startDate,
        endDate: uiCondition.endDate
      });

      logEvent(AMP_PRESS_ENTER_SUMMARY_TINY_CONDITION, params);

      conditionActions.setCondition(params);
    });
  };

  return (
    <KeywordControl
      labelText="제목"
      inputKeyword={uiCondition.includeTinyKor}
      onChange={setStringInput}
      onEnter={onSearch}
    />
  );
}
