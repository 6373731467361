export enum KIS_EXCHANGE_CODE {
  SZS = "SZS",
  SHS = "SHS",
  HKG = "HKG",
  TYO = "TYO",
  NYY = "NYY",
  BTQ = "BTQ",
  NQQ = "NQQ",
  ASQ = "ASQ",
  PNK = "PNK",
  HNX = "HNX",
  HSX = "HSX"
}
