import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";

export type PV_CATEGORY_MAPPING_USA_KEY = keyof typeof CATEGORY_MAPPING_USA;
const CATEGORY_USA_MASTER = [
  {
    value: "3",
    kor: "내부자지분취득",
    org: "3"
  },
  {
    value: "4",
    kor: "내부자지분변동",
    org: "4"
  },
  {
    value: "5",
    kor: "내부자지분변동(연)",
    org: "5"
  },
  {
    value: "3/A",
    kor: "내부자지분취득",
    org: "3/A"
  },
  {
    value: "4/A",
    kor: "내부자지분변동",
    org: "4/A"
  },
  {
    value: "5/A",
    kor: "내부자지분변동(연)",
    org: "5/A"
  },
  {
    value: "6-K",
    kor: "수시공시(외)",
    org: "6-K"
  },
  {
    value: "6-K/A",
    kor: "수시공시(외)",
    org: "6-K/A"
  },
  {
    value: "8-K",
    kor: "수시공시",
    org: "8-K"
  },
  {
    value: "8-K/A",
    kor: "수시공시",
    org: "8-K/A"
  },
  {
    value: "10-K",
    kor: "연간보고서",
    org: "10-K"
  },
  {
    value: "10-K/A",
    kor: "연간보고서",
    org: "10-K/A"
  },
  {
    value: "10-Q",
    kor: "분기보고서",
    org: "10-Q"
  },
  {
    value: "10-Q/A",
    kor: "분기보고서",
    org: "10-Q/A"
  },
  {
    value: "DEF 14A",
    kor: "주주총회",
    org: "DEF 14A"
  },
  {
    value: "20-F",
    kor: "연간보고서(외)",
    org: "20-F"
  },
  {
    value: "20-F/A",
    kor: "연간보고서(외)",
    org: "20-F/A"
  },
  {
    value: "40-F",
    kor: "연간보고서(캐)",
    org: "40-F"
  },
  {
    value: "40-F/A",
    kor: "연간보고서(캐)",
    org: "40-F/A"
  },
  {
    value: "424B3",
    kor: "증권공모",
    org: "424B3"
  },
  {
    value: "424B3/A",
    kor: "증권공모",
    org: "424B3/A"
  },
  {
    value: "SC 13D",
    kor: "대주주지분변동",
    org: "SC 13D"
  },
  {
    value: "SC 13D/A",
    kor: "대주주지분변동",
    org: "SC 13D/A"
  },
  {
    value: "SC 13G",
    kor: "대주주지분변동",
    org: "SC 13G"
  },
  {
    value: "SC 13G/A",
    kor: "대주주지분변동",
    org: "SC 13G/A"
  }
];

export const CATEGORY_MAPPING_USA = CATEGORY_USA_MASTER.reduce(
  (acc, cur) => {
    acc[cur.value] = cur;
    return acc;
  },
  {} as {
    [key in string]: { kor: string; value: string; org: string };
  }
);
const CATEGORY_MAPPING_USA_MAP = new Map<string, Array<string>>();
Object.keys(CATEGORY_MAPPING_USA).forEach((v) => {
  const key = v as PV_CATEGORY_MAPPING_USA_KEY;
  const value = CATEGORY_MAPPING_USA[key].kor;
  const list =
    CATEGORY_MAPPING_USA_MAP.get(CATEGORY_MAPPING_USA[key].kor) ?? [];
  if (list.length === 0) {
    CATEGORY_MAPPING_USA_MAP.set(value, list);
  }
  list.push(v);
});

export const USA_CATEGORY_OPTIONS: Array<PV_SELECT_OPTION<Array<string>>> = [
  ...CATEGORY_MAPPING_USA_MAP.keys()
]
  .sort((a, b) => a.localeCompare(b))
  .map((key) => {
    const targetValue = CATEGORY_MAPPING_USA_MAP.get(key) ?? [];

    return {
      shortLabel: key,
      longLabel: targetValue.join(", "),
      resultLabel: key,
      value: targetValue
    };
  });
