import InputLabel from "@/components/atomic/atoms/InputLabel";
import SelectResult from "@/components/atomic/molecules/SelectResult";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";
import "./index.css";

type Props<T> = {
  labelText: string;
  selectedItem: PV_SELECT_OPTION<T> | null;
  onSelect: (v: PV_SELECT_OPTION<T> | null) => void;
  list?: Array<PV_SELECT_OPTION<T>>;
  needClear?: boolean;
  selectorFontClass?: string;
  resultFontClass?: string;
  dataType: string;
  clearText?: string;
  showResult?: boolean;
  disabled?: boolean;
};

export default function SelectControl<T = string>({
  labelText,
  selectedItem,
  onSelect,
  list = [],
  needClear,
  selectorFontClass,
  resultFontClass,
  dataType,
  clearText = "전체",
  showResult,
  disabled
}: Props<T>) {
  return (
    <div className="atomic organisms select-control">
      <div className="label-box">
        <InputLabel labelText={labelText} />
      </div>
      <div className="search-condition-box">
        <SelectResult<T>
          showResult={showResult}
          needClear={needClear}
          list={list}
          onSelect={onSelect}
          selectedItem={selectedItem}
          selectorFontClass={selectorFontClass}
          resultFontClass={resultFontClass}
          dataType={dataType}
          clearText={clearText}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
