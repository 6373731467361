import "./index.css";

export default function ResultShow({
  value,
  // resultFontClass = "font-medium",
  resultFontClass = "",
  clearText
}: {
  value: string;
  resultFontClass?: string;
  clearText: string;
}) {
  return (
    <div
      className={["atomic atoms result-show bordered", resultFontClass].join(
        " "
      )}
      title={value || clearText}
    >
      <span>{value || clearText}</span>
    </div>
  );
}
