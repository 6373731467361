import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";

export type PV_CATEGORY_MAPPING_SHENZHEN_KEY =
  keyof typeof CATEGORY_MAPPING_SHENZHEN;
const CATEGORY_SHENZHEN_MASTER = [
  {
    value: "010301",
    kor: "연간보고서",
    org: "010301"
  },
  {
    value: "010303",
    kor: "2분기보고서",
    org: "010303"
  },
  {
    value: "010305",
    kor: "1분기보고서",
    org: "010305"
  },
  {
    value: "010307",
    kor: "3분기보고서",
    org: "010307"
  },
  {
    value: "0102",
    kor: "기업공개/상장",
    org: "0102"
  },
  {
    value: "0105",
    kor: "주식발행/증자",
    org: "0105"
  },
  {
    value: "0107",
    kor: "증자/제3자배정",
    org: "0107"
  },
  {
    value: "0109",
    kor: "전환사채",
    org: "0109"
  },
  {
    value: "0110",
    kor: "신주인수권부채권",
    org: "0110"
  },
  {
    value: "0111",
    kor: "기타융자",
    org: "0111"
  },
  {
    value: "0113",
    kor: "배당/보호예수해제",
    org: "0113"
  },
  {
    value: "0115",
    kor: "지분변경",
    org: "0115"
  },
  {
    value: "0117",
    kor: "투자/거래",
    org: "0117"
  },
  {
    value: "0119",
    kor: "주주총회",
    org: "0119"
  },
  {
    value: "0121",
    kor: "정정/경고/실적전망",
    org: "0121"
  },
  {
    value: "0125",
    kor: "유의종목/상장폐지",
    org: "0125"
  },
  {
    value: "0127",
    kor: "정정공시",
    org: "0127"
  },
  {
    value: "0129",
    kor: "외부감사보고",
    org: "0129"
  },
  {
    value: "0131",
    kor: "경영주요공시",
    org: "0131"
  },
  {
    value: "0139",
    kor: "채권발행",
    org: "0139"
  },
  {
    value: "0123",
    kor: "기타주요사항",
    org: "0123"
  },
  {
    value: "01239901",
    kor: "이사회공시",
    org: "01239901"
  },
  {
    value: "01239910",
    kor: "감사위원회공시",
    org: "01239910"
  }
];

export const CATEGORY_MAPPING_SHENZHEN = CATEGORY_SHENZHEN_MASTER.reduce(
  (acc, cur) => {
    acc[cur.value] = cur;
    return acc;
  },
  {} as {
    [key in string]: { kor: string; value: string; org: string };
  }
);
const CATEGORY_MAPPING_SHENZHEN_MAP = new Map<string, Array<string>>();
Object.keys(CATEGORY_MAPPING_SHENZHEN).forEach((v) => {
  const key = v as PV_CATEGORY_MAPPING_SHENZHEN_KEY;
  const value = CATEGORY_MAPPING_SHENZHEN[key].kor;
  const list =
    CATEGORY_MAPPING_SHENZHEN_MAP.get(CATEGORY_MAPPING_SHENZHEN[key].kor) ?? [];
  if (list.length === 0) {
    CATEGORY_MAPPING_SHENZHEN_MAP.set(value, list);
  }
  list.push(v);
});

export const SHENZHEN_CATEGORY_OPTIONS: Array<PV_SELECT_OPTION<Array<string>>> =
  [...CATEGORY_MAPPING_SHENZHEN_MAP.keys()]
    .sort((a, b) => a.localeCompare(b))
    .map((key) => {
      const targetValue = CATEGORY_MAPPING_SHENZHEN_MAP.get(key) ?? [];

      return {
        shortLabel: key,
        longLabel: targetValue.join(", "),
        resultLabel: key,
        value: targetValue
      };
    });
