import { convertCategoryCodeToName } from "@/api/fetchDisclosureList/common/convertCategoryCodeToName";
import { parseTopic } from "@/api/fetchDisclosureList/common/parseTopic";
import { GQL_ENUM_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_DISCLOSURE_ITEM } from "@/types/PresenterModel/PV_DISCLOSURE_ITEM";
import { API_RES_SHENZHEN_LIST_ITEM } from "../../../types/apiModel/Disclosure/API_RES_DISCLOSURE_SHENZHEN";

export function parseShenzhenListItem(
  v: API_RES_SHENZHEN_LIST_ITEM,
  exchangeType: GQL_ENUM_EXCHANGE_TYPE
): PV_DISCLOSURE_ITEM | null {
  if (!v.id) return null;

  return {
    id: v.id,
    dataDate: v.dataDate ?? "",
    securityCode: v.details.secCode ? v.details.secCode.filter((v) => v) : [],
    securityName: v.korName ?? "",
    orgSecurityName: v.details.secName?.[0] ?? "",
    categoryCode: v.details.categoryId?.[0] ?? "",
    categoryName: convertCategoryCodeToName(
      exchangeType,
      v.details.categoryId?.[0]
    ),
    fileUrl: `https://www.szse.cn/api/disc/info/download?id=${v.id}`,
    tags: parseTopic(v.analysisDetails.topicKor),
    summaryShort: v.analysisDetails.summarizeTinyKor ?? "",
    summaryLong: v.analysisDetails.summarizeLongKor ?? "",
    exchangeType,
    formData: null
  };
}
