import InputLabel from "@/components/atomic/atoms/InputLabel";
import { ChangeEventHandler, KeyboardEventHandler } from "react";
import "./index.css";

type Props = {
  inputKeyword: string;
  labelText: string;
  onChange: (v: string) => void;
  inputFontSize?: string;
  onEnter?: () => void;
};

export default function KeywordControl({
  inputKeyword,
  labelText,
  onChange,
  // inputFontSize = " font-medium",
  inputFontSize = "",
  onEnter
}: Props) {
  const handleOnInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value ?? "");
  };
  const handleOnKeydown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      onEnter && onEnter();
    }
  };

  return (
    <div className="atomic organisms keyword-control">
      <div className="label-box">
        <InputLabel labelText={labelText} />
      </div>
      <div className="input-box">
        <input
          value={inputKeyword}
          onKeyDown={handleOnKeydown}
          onChange={handleOnInputChange}
          className={["bordered", inputFontSize].join(" ")}
        />
      </div>
    </div>
  );
}
