import Form10 from "@/components/DisclosureDetail/FormDetail/Form10";
import Form14 from "@/components/DisclosureDetail/FormDetail/Form14";
import { useContentFontStore } from "@/components/_store/zustand/useContentFontStore";
import { useSelectItemStore } from "@/components/_store/zustand/useSelectItemStore";
import { PV_DISCLOSURE_DETAIL } from "@/shared/Disclosure/types/PresenterModel/PV_DISCLOSURE_DETAIL";
import { useEffect, useRef } from "react";
import "./index.css";

function FormDetailContent({ data }: { data: PV_DISCLOSURE_DETAIL }) {
  const fontSize = useContentFontStore(
    (state) => state.actions.getCurrentFontSize() + "px"
  );
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [data]);

  return (
    <div className="content-box main-container-border">
      <div id="formDetail">
        <div id="title" className=" bg-title">
          <span>상세 내용</span>
        </div>
        <div id="contentBox" style={{ fontSize }} ref={ref}>
          {data.categoryType === "form10" && <Form10 data={data.detail} />}
          {data.categoryType === "form14" && <Form14 data={data.detail} />}
        </div>
      </div>
    </div>
  );
}

export default function FormDetail() {
  const data = useSelectItemStore((state) => state.item?.detail);

  return <>{data?.categoryType ? <FormDetailContent data={data} /> : <></>}</>;
}
