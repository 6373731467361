import { SYS_ZUSTAND_SET } from "@/shared/common/types/zustand/common";
import { create } from "zustand";

type STORE = FIELD & { actions: INTERFACE };

type FIELD = {
  state: {
    needPrint: boolean;
  };
};

const initField = (): FIELD => {
  return {
    state: {
      needPrint: false
    }
  };
};

type INTERFACE = {
  setNeedPrint: (needPrint: boolean) => void;
  reset: () => void;
};

const baseInfoInterface = (set: SYS_ZUSTAND_SET<STORE>): INTERFACE => {
  return {
    setNeedPrint: (needPrint) => {
      set({ state: { needPrint } });
    },
    reset: () => set(initField())
  };
};

export const usePrintStore = create<STORE>((set) => ({
  ...initField(),
  actions: {
    ...baseInfoInterface(set)
  }
}));
