import ContentTextBox from "@/components/DisclosureDetail/ArticleDetail/ContentTextBox";
import ContentTitleBox from "@/components/DisclosureDetail/ArticleDetail/ContentTitleBox";
import NoData from "@/components/DisclosureList/TableBody/NoData";
import { useSelectItemStore } from "@/components/_store/zustand/useSelectItemStore";
import { useEffect, useRef } from "react";
import "./index.css";

function ArticleDetailContent() {
  const id = useSelectItemStore((state) => state.item?.selectedItem?.id);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [id]);

  return (
    <div id="articleDetail">
      <div id="title" className=" bg-title">
        <span>내용</span>
      </div>
      <div id="contentBox" ref={ref}>
        <ContentTitleBox />
        <ContentTextBox />
      </div>
    </div>
  );
}

export default function ArticleDetail() {
  const hasItem = useSelectItemStore((state) => !!state.item);

  return (
    <div className="content-box main-container-border">
      {hasItem ? <ArticleDetailContent /> : <NoData />}
    </div>
  );
}
