import MainTemplate from "@/components/MainTemplate";
import { AMP_INIT_LIST_PAGE, logEvent } from "@/utils/logEvent";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const MainPage = () => {
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    const countryCode = urlParams.get("country_code");
    const exchange =
      urlParams.get("exchange") || urlParams.get("exchange_code");
    const secCode = urlParams.get("sec_code") || urlParams.get("security_code");

    logEvent(AMP_INIT_LIST_PAGE, {
      countryCode,
      exchange,
      secCode
    });
  }, []);
  return <MainTemplate />;
};

export default MainPage;
