import "./index.css";

import { ReactComponent as CalendarImg } from "./bx-calendar.svg";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export default function CalendarBtn(props: Props) {
  return (
    <button
      type="button"
      {...props}
      className={[
        "bordered atomic atoms simple-part calendar-btn bg-white",
        props.className
      ].join(" ")}
    >
      <CalendarImg />
    </button>
  );
}
