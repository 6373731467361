export const SECURITY_CODE_NAMESPACE = "securityCode";

export function setJsonData(namespace: string, jsonData?: object | null) {
  if (!namespace) {
    return;
  }
  if (!jsonData) {
    localStorage.removeItem(namespace);
  }
  try {
    localStorage.setItem(namespace, JSON.stringify(jsonData));
  } catch (e) {
    console.log("fail to store data");
  }
}

export function getJsonData(namespace: string) {
  if (!namespace) {
    return;
  }
  try {
    const orgData = localStorage.getItem(namespace);
    if (!orgData) {
      return;
    }
    return JSON.parse(orgData);
  } catch (e) {
    console.log("fail to restore data");
  }
}
