import "./index.css";

import { ReactComponent as DropDownBtnSvg } from "./DropDownBtn.svg";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export default function DropDownBtn(props: Props) {
  return (
    <button
      type="button"
      {...props}
      className={[
        "bordered atomic atoms simple-part drop-down-btn bg-white",
        props.className
      ].join(" ")}
    >
      <DropDownBtnSvg />
    </button>
  );
  // return <img src={DropDownImg} alt="dropdown" height="16px" width="11px" />;
}
