import { GQL_TYPE_DISCLOSURE_ANALYSIS_TO_USA_DEF14A } from "@/shared/Disclosure/types/GQL/model/DisclosureDetails/types/form/DisclosureAnalysisToUSADEF14A";

export function parseUSAForm14(
  id: string,
  data?: GQL_TYPE_DISCLOSURE_ANALYSIS_TO_USA_DEF14A
) {
  return data && data?.proposalKorList && data?.proposalKorList.length > 0
    ? { ...data, id }
    : null;
}
