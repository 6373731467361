import { useUiConditionStore } from "@/components/_store/zustand/useUiConditionStore";
import SelectControl from "@/components/atomic/organisms/SelectControl";
import { NATIONAL_OPTION_LIST } from "@/constants/NATIONAL_OPTION_LIST";
import { GQL_EXCHANGE_TYPE } from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { PV_SELECT_OPTION } from "@/types/PresenterModel/PV_SELECT_OPTION";
import { useState } from "react";

export default function Nation() {
  const selectItem = useUiConditionStore(
    (state) => (v: PV_SELECT_OPTION<GQL_EXCHANGE_TYPE> | null) =>
      v
        ? state.actions.selectItem("exchangeCode", v)
        : state.actions.resetByExchangeCode(),
    () => true
  );

  const selectedItem = useUiConditionStore(
    (state) => state.exchangeCode.selected
  );

  const [list] = useState([...NATIONAL_OPTION_LIST]);

  return (
    <SelectControl<GQL_EXCHANGE_TYPE>
      onSelect={selectItem}
      selectedItem={selectedItem}
      list={list}
      needClear={false}
      labelText="국가"
      dataType="nation"
      // selectorFontClass="font-medium space-small"
      // resultFontClass="font-medium space-small"
    />
  );
}
