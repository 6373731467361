import { CATEGORY_MAPPING_UNKNOWN } from "@/shared/Disclosure/constants/DisclosureCategoryNameConstants";
import { CATEGORY_MAPPING_HONGKONG } from "@/shared/Disclosure/constants/HONGKONG_CATEGORY_OPTIONS";
import { CATEGORY_MAPPING_JAPAN } from "@/shared/Disclosure/constants/JAPAN_CATEGORY_OPTIONS";
import { CATEGORY_MAPPING_SHANGHAI } from "@/shared/Disclosure/constants/SHANGHAI_CATEGORY_OPTIONS";
import { CATEGORY_MAPPING_SHENZHEN } from "@/shared/Disclosure/constants/SHENZHEN_CATEGORY_OPTIONS";
import { CATEGORY_MAPPING_USA } from "@/shared/Disclosure/constants/USA_CATEGORY_OPTIONS";
import { CATEGORY_MAPPING_VIETNAM } from "@/shared/Disclosure/constants/VIETNAM_CATEGORY_OPTIONS";
import {
  GQL_ENUM_EXCHANGE_TYPE,
  GQL_EXCHANGE_TYPE
} from "@/shared/Disclosure/types/GQL/enum/ExchangeType";
import { isFieldOf } from "@/shared/common/utils/object/objectUtils";

type categoryMapObjType = {
  [GQL_EXCHANGE_TYPE.USA]: typeof CATEGORY_MAPPING_USA;
  [GQL_EXCHANGE_TYPE.HONGKONG]: typeof CATEGORY_MAPPING_HONGKONG;
  [GQL_EXCHANGE_TYPE.JAPAN]: typeof CATEGORY_MAPPING_JAPAN;
  [GQL_EXCHANGE_TYPE.SHANGHAI]: typeof CATEGORY_MAPPING_SHANGHAI;
  [GQL_EXCHANGE_TYPE.SHENZHEN]: typeof CATEGORY_MAPPING_SHENZHEN;
  [GQL_EXCHANGE_TYPE.HANOI]: typeof CATEGORY_MAPPING_VIETNAM;
};

const categoryMapObj: categoryMapObjType = {
  [GQL_EXCHANGE_TYPE.USA]: CATEGORY_MAPPING_USA,
  [GQL_EXCHANGE_TYPE.HONGKONG]: CATEGORY_MAPPING_HONGKONG,
  [GQL_EXCHANGE_TYPE.JAPAN]: CATEGORY_MAPPING_JAPAN,
  [GQL_EXCHANGE_TYPE.SHANGHAI]: CATEGORY_MAPPING_SHANGHAI,
  [GQL_EXCHANGE_TYPE.SHENZHEN]: CATEGORY_MAPPING_SHENZHEN,
  [GQL_EXCHANGE_TYPE.HANOI]: CATEGORY_MAPPING_VIETNAM
};

export function convertCategoryCodeToName(
  exchangeCode: GQL_ENUM_EXCHANGE_TYPE,
  categoryId?: string | null
) {
  if (!categoryId) return CATEGORY_MAPPING_UNKNOWN;

  if (!isFieldOf(categoryMapObj, exchangeCode)) return CATEGORY_MAPPING_UNKNOWN;

  const categoryMap = categoryMapObj[exchangeCode];

  if (!isFieldOf(categoryMap, categoryId)) return CATEGORY_MAPPING_UNKNOWN;

  const categoryName: { kor: string } = categoryMap[categoryId];
  return categoryName.kor || CATEGORY_MAPPING_UNKNOWN;
}

export function convertCategoryCodeToOrgName(
  exchangeCode: GQL_ENUM_EXCHANGE_TYPE,
  categoryId?: string | null
) {
  if (!categoryId) return "";

  if (!isFieldOf(categoryMapObj, exchangeCode)) return categoryId;

  const categoryMap = categoryMapObj[exchangeCode];

  if (!isFieldOf(categoryMap, categoryId)) return categoryId;

  const categoryName: { org: string } = categoryMap[categoryId];
  return categoryName.org || categoryId;
}
