export function isFieldOf<T extends object>(
  obj: T,
  key: string | number | symbol | undefined
): key is keyof T {
  if (!key) return false;
  return Object.keys(obj).some((v) => v === key);
}

type EmptyObject<T> = { [K in keyof T]?: never };
type EmptyObjectOf<T> = EmptyObject<T> extends T ? EmptyObject<T> : never;

export function isEmptyObject<T extends object>(
  value: T | null | undefined
): value is EmptyObjectOf<T> {
  if (!value) return true;
  if (Object.keys(value).length === 0) return true;
  return false;
}
